import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';

export const SuccessNote = ({ isSubmitted, handleCloseAlert }) => {
  const [visible, setVisible] = React.useState(true);

  React.useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  if (!visible) {
    return null;
  }
  // handleCloseAlert()
  return (
    <Stack
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        right: 0,
        opacity: visible ? 1 : 0,
        transition: 'opacity 1s ease-out',
      }}
      spacing={2}
    >
      <Alert severity="success">
        <AlertTitle><FormattedMessage id="add.note.success" /></AlertTitle>
      </Alert>
    </Stack>
  );
}

export const ErrorNote = ({ error, handleCloseAlert = null }) => {
  const [visible, setVisible] = React.useState(true);
  React.useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setVisible(false);
        handleCloseAlert && handleCloseAlert()
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  if (!visible) {
    return null;
  }

  return (
    <Stack
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        right: 0,
        opacity: visible ? 1 : 0,
        transition: 'opacity 1s ease-out',
      }}
      spacing={2}
    >
      <Alert severity="error">
        <AlertTitle><FormattedMessage id="error.note" /></AlertTitle>
        {error}
      </Alert>
    </Stack>
  );
}

// import React from 'react';
// import Alert from '@mui/material/Alert';
// import AlertTitle from '@mui/material/AlertTitle';
// import Stack from '@mui/material/Stack';
// import { FormattedMessage } from 'react-intl';

// export const SuccessNote = ({ isSubmitted }) => {
//   React.useEffect(() => {
//     let timer;
//     if (isSubmitted) {
//       timer = setTimeout(() => {
//         handleCloseAlert();
//       }, 5000);
//     }

//     return () => {
//       clearTimeout(timer);
//     };
//   }, [isSubmitted, handleCloseAlert]);

//   if (!isSubmitted) {
//     return null;
//   }

//   return (
//     <Stack
//       sx={{
//         width: '100%',
//         position: 'fixed',
//         bottom: 0,
//         right: 0,
//         opacity: 1,
//         transition: 'opacity 1s ease-out',
//       }}
//       spacing={2}
//     >
//       <Alert severity="success">
//         <AlertTitle><FormattedMessage id="add.note.success"/></AlertTitle>
//       </Alert>
//     </Stack>
//   );
// };

// export const ErrorNote = ({ error, handleCloseAlert }) => {
//   React.useEffect(() => {
//     let timer;
//     if (error) {
//       timer = setTimeout(() => {
//         handleCloseAlert();
//       }, 5000);
//     }

//     return () => {
//       clearTimeout(timer);
//     };
//   }, [error]);

//   if (!error) {
//     return null;
//   }

//   return (
//     <Stack
//       sx={{
//         width: '100%',
//         position: 'fixed',
//         bottom: 0,
//         right: 0,
//         opacity: 1,
//         transition: 'opacity 1s ease-out',
//       }}
//       spacing={2}
//     >
//       <Alert severity="error">
//         <AlertTitle><FormattedMessage id="error.note"/></AlertTitle>
//         {error}
//       </Alert>
//     </Stack>
//   );
// };
