import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import TitleSection from 'common/components/Message/TitleSection';
import { IconButton, Tooltip } from '@material-ui/core';
import { Backspace, AspectRatio } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import Add from 'views/riskmatrix/notes/component/Add';
import { NotesReceived } from 'common/components/NotesReceived'
import { SuccessNote } from 'common/components/SuccessNote'

const Notes = ({
  token, domain, customerId, type, isAdmin = false,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      { !visible && (
        <Tooltip title={<FormattedMessage id="view.notes" />}>
          <IconButton onClick={() => setVisible(true)}>
            <AspectRatio color="primary" style={{ fontSize: 30 }} />
          </IconButton>
        </Tooltip>
      )
      }
      {visible
        && (
        <Card style={{ padding: 20 }}>
          <div>
            <div style={{ float: 'right' }}>
              <Tooltip title={<FormattedMessage id="close" />}>
                <IconButton onClick={() => setVisible(false)}>
                  <Backspace color="error" />
                </IconButton>
              </Tooltip>
            </div>
            <TitleSection id="notes" separator="5px" size={25} />
          </div>
          {type && customerId && domain && (
            <React.Fragment>
              {
                isAdmin && <Add setIsSubmitted={setIsSubmitted} />
              }
              <NotesReceived token={token} domain={domain} customerId={customerId} type={type} setIsSubmittedNote={isSubmitted} />
            </React.Fragment>
          )}
        </Card>
        )
      }
      {isSubmitted && <SuccessNote isSubmitted={isSubmitted} />}
    </div>
  );
};

export default Notes;
