import React, { useState } from 'react';
import SearchViewTable from 'common/components/SearchViewTable'
import GraphsSearchFeed from 'common/components/GraphsSearchFeed'
import TableMatrizSearchFeed from 'common/components/TableMatrizSearchFeed'
import Fade from '@mui/material/Fade';


export const ChartViewer = ({ data }) => {
  const valuesDkimToRender = data.values.slice(0, 4);
  const firstRowValues = data.values.slice(5, 10);
  const secondRowValues = data.values.slice(10);
  const totalMessagesNumber = data.values.slice(4, 5);
  const [page, setPage] = useState(0);
  // dependiendo la opcion elegida mostramos una u otra informacion
  const [option, setOption] = useState(null)
  const handleOptionClick = ({ index }) => {
    setOption(index)
    setPage(0)
  }

  const firstDetailsRowValues = option !== null ? data.matriz[option].values.slice(1, 6) : null;
  const secondDetailsRowValues = option !== null ? data.matriz[option].values.slice(6) : null;
  const totalDetailsMessagesNumber = option !== null ? data.matriz[option].values.slice(0, 1) : null;
  const records = option !== null ? data.matriz[option].records : null;


  return (
    <React.Fragment>
      <Fade in timeout={1000}>
        <div style={{
          backgroundColor: 'white', margin: '10px auto', borderRadius: '3px', padding: '20px', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 10px -2px, rgba(0, 0, 0, 0.06) 0px 4px 10px -2px, rgba(0, 0, 0, 0.06) 0px 4px 10px -2px',
        }}
        >
          <GraphsSearchFeed valuesDkimToRender={valuesDkimToRender} handleOptionClick={handleOptionClick} />
          <SearchViewTable totalMessagesNumber={totalMessagesNumber} firstRowValues={firstRowValues} secondRowValues={secondRowValues} />
        </div>
      </Fade>
      {option !== null && (
      <TableMatrizSearchFeed
        option={option}
        records={records}
        totalDetailsMessagesNumber={totalDetailsMessagesNumber}
        secondDetailsRowValues={secondDetailsRowValues}
        firstDetailsRowValues={firstDetailsRowValues}
        page={page}
        setPage={setPage}
      />
      )
      }
    </React.Fragment>
  );
};
// 200.6.27.34
