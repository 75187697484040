import React, { useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect, useDispatch, useSelector } from 'react-redux';
import { format, startOfMonth } from 'date-fns';
import { asyncActions } from 'rdx/summary/actions'
import { currentDomainSelector, domainDetailsSelector } from 'rdx/summary/selectors';
import Layout from 'common/components/Layout';
import Grid from '@material-ui/core/Grid';

import { Scroll } from 'common/utils/services/scroll.service'
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import { RangeSelector } from 'common/components/RangeSelector';
import Tools from 'views/riskmatrix/tool/Tools';
import dataApi from 'rdx/newRedux/api/dataApi';
import Action from 'rdx/newRedux/actions/Action';
import { GET_NOTES } from 'rdx/newRedux/types';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_ADITIONAL_NOTE, KEY_NOTES } from 'rdx/newRedux/selectores/keys';
import { parseNotes } from 'rdx/newRedux/parser/notes';
import ChartRow from './ChartRow';
import FilterToggle from './FilterToggle';
import Matrix from './Matrix';
import DomainHeader from './DomainHeader'
import Domains from './Domains';
import InfoRow from './InfoRow';
import Details from './Details';
import Notes from './notes/View';


const MatrixDashboard = ({
  customerId, domain, loadPrecalculated, domainDetails, isAdmin, email,
}) => {
  const dispatch = useDispatch();
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const [selectedRange, setSelectedRange] = useState(14);
  React.useEffect(() => {
    if (customerId && isEmpty(domainDetails)) loadPrecalculated({ customerId, email });
    Scroll.scrollTop()
  }, [customerId]);
  React.useEffect(() => {
    if (customerId && domain) {
      const payload = {
        params: {
          id: customerId,
          domain,
          dateStart: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
          dateEnd: format(new Date(), 'yyyy-MM-dd'),
        },
        ...dataApi.getNotes,
        parser: (d) => parseNotes(d),
      }
      dispatch(Action(GET_NOTES, payload));
    }
  }, [customerId, domain])

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FilterToggle
                ready
                showLink
                onClick={() => {}}
              />
              <RangeSelector onSelect={(range) => setSelectedRange(range)} />
            </Grid>
            <Grid item xs={12}>
              <Domains />
            </Grid>
            {/*            <Grid item xs={12}>
              <Dates />
            </Grid> */}
            <Grid item xs={12}>
              <Tools />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid item xs={12}>
            <DomainHeader domain={domain} />
          </Grid>
          <InfoRow
            authenticated={get(domainDetails, 'totalAuthenticated', {})}
            dmarc={get(domainDetails, 'dmarc', {})}
            totalMessages={get(domainDetails, 'totalMessages')}
          />

          <ChartRow />
          { noteData && domain && customerId && <Notes token={noteData.jwtToken} domain={domain} customerId={customerId} type={selectedRange} isAdmin={isAdmin === 'Y'} /> }
          <Matrix
            state={get(domainDetails, 'matrixState')}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Details />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

const mapDispatchToProps = {
  loadPrecalculated: asyncActions.loadAction,
}
const mapStateToProps = (state) => ({
  domain: currentDomainSelector(state),
  domainDetails: domainDetailsSelector(state),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(
  withUserInfo(MatrixDashboard)
);
