import React, { useState, useEffect } from 'react'
import { ChartViewer } from 'common/components/ChartViewer'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from 'react-intl';
import { SearchContainerView } from './SearchContainerView';
// 40.107.93.105 IP de farmacias

async function fetchData({
  token, id, domain, type, sender = null, ip,
}) {
  const BASE_API = 'https://q4j7e7uwc0.execute-api.us-east-1.amazonaws.com/v1/filters/find-ip/display-records-matrix?'
  let query = `id=${id}&domain=${domain}&type=${type}&ip=${ip}`
  if (sender) query += `&sender=${sender}`
  try {
    const url = BASE_API + query;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los datos.');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function fetchDataUrl({ url }) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      redirect: 'follow',
      headers: {
        // Authorization: `Bearer ${token}`,
        // 'Content-Type': 'aplication/json',
        // 'x-amz-server-side-encryption': 'AES256',
        // 'Access-Control-Allow-Origin': '*',
        // Follow: 'Authorization header',

      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los datos.');
    }

    const data = await response.json();
    // console.log(data)
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export const SearchContainer = ({
  token, type, domain, id,
}) => {
  const [inputValues, setInputValues] = useState({ ip: '', sender: null })
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [moreDataLoading, setMoreDataLoading] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [data, setData] = useState(null)
  const [validateData, setValidateData] = useState({
    token: '', id: '', domain: '', type: '', ip: '',
  })
  const handleInputChange = (event, fieldName) => {
    setInputValues({ ...inputValues, [fieldName]: event.target.value });
  };
  useEffect(() => {
    setData(null);
  }, [domain]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null)
    if (inputValues.ip === '') {
      setFormValid(false);
      return;
    }
    const { ip } = inputValues;
    if (
      ip === validateData.ip
      && token === validateData.token
      && id === validateData.id
      && domain === validateData.domain
      && type === validateData.type
    ) {
      // Los datos son los mismos, no hagas una nueva solicitud
      return;
    }
    try {
      setData(null)
      setIsLoading(true);
      const response = await fetchData({
        token, id, domain, type, ip,
      });
      const responseData = await response;
      setData(responseData);
      setValidateData({
        token, id, domain, type, ip,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Error al obtener los datos.');
    }
  };


  async function GetMoreData() {
    const { url } = data;
    try {
      setData(null)
      setMoreDataLoading(true);
      const response = await fetchDataUrl({
        url,
      });
      const responseData = await response;
      setData(responseData);
    } catch (error) {
      setErrorMessage('Error al obtener los datos.');
    } finally {
      setMoreDataLoading(false)
    }
  }
  if (data && data.url.length > 0) {
    GetMoreData()
  }
  return (
    <Grid item md={8} xs={12} style={{ margin: '0 auto' }}>
      <Grid item md={12} xs={12} style={{ margin: '0 auto' }}>
        <SearchContainerView
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          errorMessage={errorMessage}
          formValid={formValid}
          isLoading={isLoading}
          data={data}
          inputValues={inputValues}
        />
        {isLoading && (
          <div style={{
            width: '100%', height: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <CircularProgress disableShrink size="150px" />
          </div>
        )
         }
        {moreDataLoading && (
        <div style={{
          width: '100%', height: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <CircularProgress disableShrink size="150px" />
        </div>
        )
         }
        {data && data.count <= 0 && <h2 style={{ marginTop: '35px', textAlign: 'center' }}><FormattedMessage id="NoResultsFound" /></h2>}
        {data && data.count > 0 && <ChartViewer data={data} />}
        {errorMessage && <h3 style={{ margin: '40px 0px', textAlign: 'center' }}><FormattedMessage id="AnErrorHasOcurred" /></h3>}
      </Grid>
    </Grid>
  )
}
