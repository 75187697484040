import React, { useState, useEffect } from 'react'
import Layout from 'common/components/Layout';
import Grid from '@material-ui/core/Grid';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import TitleSection from 'common/components/Message/TitleSection';
import { RangeSelector } from 'common/components/RangeSelector';
import Domains from 'views/riskmatrix/Domains';
import { KEY_ACTUAL_COMPANY } from 'rdx/newRedux/selectores/keys';
import { useDispatch, useSelector } from 'react-redux';
import { asyncActions } from 'rdx/summary/actions';
import isEmpty from 'lodash/isEmpty';
import { currentDomainSelector, domainDetailsSelector } from 'rdx/summary/selectors';
import { SearchContainer } from './SearchContainer';


export const SearchFeed = () => {
  const actualId = GetSelector(KEY_ACTUAL_COMPANY)
  const domain = window.location.href.split('domain=').pop().trim();
  const [selectedRange, setSelectedRange] = useState(14);
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const dispatch = useDispatch();
  const domainP = useSelector(currentDomainSelector);
  const domainDetails = useSelector(domainDetailsSelector);
  let token = null;
  if (noteData)token = noteData.jwtToken
  useEffect(() => {
    if (actualId && noteData) {
      const { email } = noteData.payload;
      const customerId = actualId.id;
      if (isEmpty(domainDetails)) dispatch(asyncActions.loadAction({ customerId, email }));
    }
  }, [dispatch, domainP, domainDetails, actualId, noteData]);
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <TitleSection id="Search" />
          <Grid item xs={12}>
            <RangeSelector onSelect={(range) => setSelectedRange(range)} ranges={[1, 7, 14]} />
          </Grid>
          <Grid item xs={12}>
            <Domains type="search" />
          </Grid>
        </Grid>
        {noteData && actualId && <SearchContainer token={token} type={selectedRange} domain={domain} id={actualId.id} />}
      </Grid>
    </Layout>
  );
};
