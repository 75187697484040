import React, { useEffect } from 'react';
import { isEmpty, get } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid';

import { dashboardViewSelector } from 'rdx/summary/selectors';
import { asyncActions } from 'rdx/summary/actions';
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import Typography from '@mui/material/Typography';


import { Scroll } from 'common/utils/services/scroll.service'
import { RangeSelector } from 'common/components/RangeSelector';
import Chart from 'common/components/Chart';
import Layout from 'common/components/Layout'
import Separator from 'common/components/Separator';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_ACTUAL_COMPANY, KEY_ACTUAL_URL, KEY_IS_LOADING_DATA } from 'rdx/newRedux/selectores/keys';
import Action from 'rdx/newRedux/actions/Action';
import { ACTUAL_URL, IS_LOADIND_DATA } from 'rdx/newRedux/types';
import { withRouter } from 'react-router-dom';
import Summary from './Summary';
import Details from './Details';

const RangeContainer = styled.div`
  margin-bottom: 10px;
`;


const Dashboard = ({
  customerId, domains, total, intl, loadPrecalculated, history, email,
}) => {
  const format = intl.formatMessage;
  const actualId = GetSelector(KEY_ACTUAL_COMPANY)
  const isLoading = GetSelector(KEY_IS_LOADING_DATA);
  const url = GetSelector(KEY_ACTUAL_URL);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!actualId) {
      if (customerId) {
        loadPrecalculated({ customerId, email });
      }
    } else {
      loadPrecalculated({ customerId: actualId.id, email })
    }

    Scroll.scrollTop()
  }, [customerId, actualId, email]);
  React.useEffect(() => {
    if (isLoading) {
      dispatch(Action(IS_LOADIND_DATA, false));
      dispatch(Action(ACTUAL_URL, '/'));
      history.push(url);
    }
  }, [domains]);
  const getPercentage = (state, status) => {
    if (!isEmpty(total)) {
      return intl.formatNumber(total.getPercentage(state, status), { style: 'percent', maximumFractionDigits: 2 });
    }
    return '';
  }
  if (!isLoading) {
    return (
      <Layout>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <RangeContainer>
              <RangeSelector />
            </RangeContainer>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Chart
              variant="doughnut"
              data={[
                get(total.authorized, 'pass', 0),
                get(total.authorized, 'fail', 0),
              ]}
              extraLabel={getPercentage('authorized', 'pass')}
              labels={[
                format({ id: 'dashboard.charts.authorized' }),
                format({ id: 'dashboard.charts.unauthorized' }),
              ]}
              headerKey="dasboard.chart1.header"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Chart
              variant="doughnut"
              data={[
                get(total.authenticated, 'pass', 0),
                get(total.authenticated, 'fail', 0),
              ]}
              extraLabel={getPercentage('authenticated', 'pass')}
              labels={[
                format({ id: 'dashboard.charts.authenticated' }),
                format({ id: 'dashboard.charts.unauthenticated' }),
              ]}
              headerKey="dasboard.chart2.header"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Chart
              variant="horizontal"
              data={[
                get(total.dmarc, 'pass'),
                get(total.dmarc, 'fail'),
              ]}
              labels={[
                format({ id: 'dashboard.charts.pass' }),
                format({ id: 'dashboard.charts.fail' }),
              ]}
              headerKey="dasboard.chart3.header"
            />
          </Grid>
          <Separator />

          <Summary summary={total} amount={Object.keys(domains || {}).length} />

          <Separator />
          <Grid container>
            <Grid item md={12} xs={12}>
              <Details details={domains} />
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    )
  }
  return null
}
const mapDispatchToProps = {
  loadPrecalculated: asyncActions.loadAction,
}
const mapStateToProps = (state) => dashboardViewSelector(state)
export default
injectIntl(connect(mapStateToProps, mapDispatchToProps)(withUserInfo(withRouter(Dashboard))));
