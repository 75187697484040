import React, { useState } from 'react';
import { currentDomainSelector } from 'rdx/summary/selectors';
import { connect, useSelector } from 'react-redux';
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import { IconButton } from '@material-ui/core';
import { AddComment } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import { FormularioNotas } from '../CreationNoteModal';

async function sendPutRequest({ requestData, token }) {
  // Configuración de la solicitud PUT
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestData),
  };

  try {
    const response = await fetch('https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/v1/notes/insert-notes', requestOptions);
    if (!response.ok) throw new Error('Error en la peticion ')
    // const data = await response.json();
    // console.log('Respuesta de la solicitud PUT:', data);
  } catch (error) {
    // console.error('Error al realizar la solicitud PUT:', error);
  }
}

const Add = ({
  customerId, domain, email, setIsSubmitted,
}) => {
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Tooltip title={<FormattedMessage id="add.note" />} placement="left">
        <IconButton onClick={() => setOpen(true)} style={{ float: 'right', marginBottom: 5 }}>
          <AddComment color="primary" />
        </IconButton>
      </Tooltip>
      {customerId && (
      <FormularioNotas
        open={open}
        onClose={() => setOpen(false)}
        customerID={customerId}
        domain={domain}
        email={email}
        noteData={noteData}
        sendPutRequest={sendPutRequest}
        setIsSubmitted={setIsSubmitted}
      />
      )}

    </React.Fragment>
  );
};

const mapDispatchToProps = {}
const mapStateToProps = (state) => ({
  domain: currentDomainSelector(state),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(
  withUserInfo(Add)
);
