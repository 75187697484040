import React from 'react';
import { asyncActions, selectDisplay } from 'rdx/summary/actions';
import {
  currentDomainSelector,
  displaySelector,
  domainDetailsSelector,
} from 'rdx/summary/selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import Layout from 'common/components/Layout';
import Grid from '@material-ui/core/Grid';
import TitleSection from 'common/components/Message/TitleSection';
import Domains from 'views/riskmatrix/Domains';
import Container from 'common/components/FlexContainer/Container';
import _ from 'lodash';
import { Scroll } from 'common/utils/services/scroll.service';
import { RangeSelector } from 'common/components/RangeSelector';
import TableDetail from 'views/reports/spfTop/component/TableDetail';
import { TOP_25_SPF_INVALID_URL, TOP_25_SPF_VALID_URL } from 'rdx/newRedux/api/url';
import apiCall from 'rdx/newRedux/api/api';
import SkeletoLoading from 'common/components/Skeleton/SkeletoLoading';
import {
  FormatNumberESService as formatNumberESService,
} from 'common/utils/services/formatNumberES.service';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import { style } from 'views/reports/spfTop/component/style';

const ContentSpf = ({
  customerId, loadPrecalculated, domainDetails, intl, domain, email, type, selected,
}) => {
  React.useEffect(() => {
    if (customerId && _.isEmpty(domainDetails)) loadPrecalculated({ customerId, email });
    Scroll.scrollTop()
  }, [customerId]);
  const compare = type === 'valid';
  const config = {
    url: compare ? 'spf-valid' : 'spf-invalid',
    title: compare ? 'spf.report.top25.valid' : 'spf.report.top25.invalid',
    urlSend: compare ? TOP_25_SPF_VALID_URL : TOP_25_SPF_INVALID_URL,
  }
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const fetch = async () => apiCall(config.urlSend, 'POST', null, {
      domain,
      client_id: customerId,
      type_query: selected,
    });

    if (domain && customerId && selected) {
      setLoading(true);
      fetch().then((d) => {
        setData(d.data);
        setLoading(false)
      }).catch((e) => console.log('error', e))
        .finally(() => setLoading(false));
    }
  }, [selected, customerId, domain])
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <TitleSection size={30} id={config.title} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Domains type={config.url} />
            </Grid>
            <Grid item xs={12}>
              <RangeSelector />
            </Grid>

          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Container marginTop={64}>
            { loading && <SkeletoLoading />}
            {!loading && (
            <React.Fragment>
              <div style={style.cardContent}>
                { (data && data.total) && (
                <Card style={style.card}>
                  { (data && data.total) ? <FormattedMessage id="spf.report.top25.total.sender" values={{ total: formatNumberESService.formatNumber(intl, data.total) }} />
                    : '' }
                </Card>
                )
                }
              </div>
              <TableDetail
                details={(data && data.data) ? data.data : []}
                intl={intl}
              />
            </React.Fragment>
            )}
          </Container>
        </Grid>
      </Grid>
    </Layout>
  );
}

const mapDispatchToProps = {
  loadPrecalculated: asyncActions.loadAction,
  setDisplay: selectDisplay,

}
const mapStateToProps = (state) => ({
  domain: currentDomainSelector(state),
  domainDetails: domainDetailsSelector(state),
  selected: displaySelector(state),
})
export default connect(
  mapStateToProps, mapDispatchToProps
)(
  withRouter(withUserInfo(injectIntl(ContentSpf)))
);
