export const REPORT_URL = '/reporte360';
export const REPORT_URL_BY_DATE = `${REPORT_URL}-dates`;
export const REPORT_FORENSIC_URL = '/Prod/forensic';
export const REPORT_HISTOGRAM_URL = '/Prod/histograma/dmarc-spf-dkim';
export const TOOLS_URL = '/Prod/tools';
export const REPORT_MONTH_URL = '/Prod/monthlyreptype1'
export const COMPANY_URL = '/PROD/companieslist';
export const LOOK_A_LIKE_URL = '/Prod/lookalike';
export const LOOK_A_LIKE_BY_IP_URL = '/Prod/lookalike/group-ip';
export const NOTES_URL = 'ADMIN/notelist';
export const CREATE_NOTE_URL = 'ADMIN/insertnote';
export const HISTORICAL_DNS_URL = '/Prod/historic-dns';
export const TOP_25_SPF_VALID_URL = 'v1/spf-reports/top-valid-ips-sent-email';
export const TOP_25_SPF_INVALID_URL = 'v1/spf-reports/top-invalid-ips-sent-email';
