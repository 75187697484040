import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons'
import TitleSection from 'common/components/Message/TitleSection';

const CustomAccordion = ({
  title, children = null, onChange, open, classNameSummary = '', titleTranslate = true,
}) => {
  return (
    <Accordion expanded={open === title} onChange={onChange(title)}>
      <AccordionSummary
        className={classNameSummary}
        expandIcon={<ExpandMore />}
        aria-controls={`${title}bh-content`}
        id={`${title}bh-header`}
      >
        <TitleSection translate={titleTranslate} separator={false} size={16} id={title} />
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
