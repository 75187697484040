import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { Grid } from '@material-ui/core';
import { CardItem } from 'common/components/FlexContainer/CardContainer';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';
import StyledCard from 'common/components/Card';
import Pie from 'views/reports/month/type/type1/Pie';
import useClass from '../style';

const body = {
  spf: [
    {
      key: 'repMenSpfPassAlgTrue',
      title: 'total.messages.spf.valid.align.valid',
    },
    {
      key: 'repMenSpfPassAlgFalse',
      title: 'total.messages.spf.valid.align.not.valid',
    },
    {
      key: 'repMenSpfNotPassAlgTrue',
      title: 'total.messages.spf.not.valid.align.valid',
    },
    {
      key: 'repMenSpfNotPassAlgFalse',
      title: 'total.messages.spf.not.valid.align.not.valid',
    },
  ],
  dkim: [
    {
      key: 'repMenDkimPassAlgTrue',
      title: 'total.messages.dkim.valid.align.valid',
    },
    {
      key: 'repMenDkimPassAlgFalse',
      title: 'total.messages.dkim.valid.align.not.valid',
    },
    {
      key: 'repMenDkimNotPassAlgTrue',
      title: 'total.messages.dkim.not.valid.align.valid',
    },
    {
      key: 'repMenDkimNotPassAlgFalse',
      title: 'total.messages.dkim.not.valid.align.not.valid',
    },
  ],
}
const Item = ({
  title, content, alternative, container = false,
}) => {
  const [over, setOver] = React.useState(null);
  return (
    <CardItem
      onMouseEnter={() => setOver('success')}
      onMouseLeave={() => setOver(null)}
      over={over}
      container={container}
    >
      <div className="title">
        <b><FormattedMessage id={title} /></b>
      </div>
      <div className="content">
        {content}
      </div>
      {alternative && over && (
        <div className="alternative">
          {alternative}%
        </div>
      )
      }
    </CardItem>
  )
}

const Type1 = ({ data = {}, intl }) => {
  const clasess = useClass();
  const content = (k) => k.map((ite) => (
    <Grid key={uniqueId()} item md={3} sm={4} xs={12}>
      <Item
        content={FormatNumberESService.formatNumber(intl, data[ite.key])}
        title={ite.title}
        alternative={FormatNumberESService
          .formatNumber(intl, ((parseFloat(data[ite.key])
            / parseFloat(data.repMenTotalMensajes)) * 100)
            .toFixed(2))}
      />
    </Grid>
  ));
  const dataGraph = (key) => body[key].map((ik) => (
    [intl.formatMessage({ id: ik.title }),
      parseFloat(data[ik.key])]));
  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <StyledCard padding="20px">
          <Grid
            container
            spacing={2}
            className={clasess.gridTotal}
          >
            <Grid item md={5} sm={6} xs={12}>
              <Item
                container
                content={FormatNumberESService.formatNumber(intl, data.repMenTotalMensajes)}
                title="total.messages.recive"
              />
            </Grid>
            <Grid item md={5} sm={6} xs={12}>
              <Item
                alternative={FormatNumberESService
                  .formatNumber(intl, ((data.repMenDmarcPass / data.repMenTotalMensajes) * 100)
                    .toFixed(2))}
                container
                content={FormatNumberESService.formatNumber(intl, data.repMenDmarcPass)}
                title="total.messages.dmarc.pass"
              />
            </Grid>
          </Grid>
        </StyledCard>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <StyledCard padding="10px">
          <div className={clasess.title}>
            <FormattedMessage id="total.messages.spf" />
          </div>
          <Grid container spacing={2}>
            {content(body.spf)}
            <Pie
              data={[
                ['percentage', 'messages percentage'],
                ...dataGraph('spf'),
              ]}
              title={intl.formatMessage({ id: 'total.messages.spf' })}
            />
          </Grid>
        </StyledCard>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <StyledCard padding="10px">
          <div className={clasess.title}>
            <FormattedMessage id="total.messages.dkim" />
          </div>
          <Grid container spacing={2}>
            {content(body.dkim)}
            <Pie
              data={[
                ['percentage', 'messages percentage'],
                ...dataGraph('dkim'),
              ]}
              title={intl.formatMessage({ id: 'total.messages.dkim' })}
            />
          </Grid>
        </StyledCard>
      </Grid>

    </Grid>
  );
};

export default injectIntl(Type1);
