import React, { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RowNotes } from 'common/components/RowNotes';
import { SuccessNote } from 'common/components/SuccessNote';
import Skeleton from '@mui/material/Skeleton';
import { FormattedMessage } from 'react-intl';

export const NotesReceived = ({
  token,
  domain,
  customerId,
  type,
  setIsSubmittedNote,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMounted = useRef(true);
  // const abortController = new AbortController();

  useEffect(() => {
    isMounted.current = true;
    setLoading(true);
    async function fetchData() {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        // signal: abortController.signal,
        // body: JSON.stringify(requestData),
      };

      try {
        const response = await fetch(
          `https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/v1/notes/all-notes/${customerId}?domain=${domain}&type=${type}`,
          requestOptions,
        );
        if (!response.ok) throw new Error('Error en la peticion ');
        const responseData = await response.json();
        if (isMounted.current) {
          setData(responseData);
          setLoading(false);
          setIsSubmitted(false);
        }
      } catch (error) {
        // console.error('Error al realizar la solicitud:', error);

        if (isMounted.current) {
          setError(error.message);
          setLoading(false);
        }
      }
    }

    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [token, domain, customerId, type, isSubmitted, setIsSubmittedNote]);

  const pendingRows = data.filter((row) => row.status === 'P');
  const closedRows = data.filter(
    (row) =>
      row.status === 'C' &&
      row.date_followup.split('-').shift().trim() !== '0000',
  );
  const noDateFollowUp = data.filter(
    (row) =>
      row.status === 'C' &&
      row.date_followup.split('-').shift().trim() === '0000',
  );

  pendingRows.sort((a, b) => {
    const dateA = new Date(a.date_followup);
    const dateB = new Date(b.date_followup);
    return dateB - dateA;
  });

  closedRows.sort((a, b) => {
    const dateA =
      a.date_followup === '0000-00-00'
        ? new Date('9999-12-31')
        : new Date(a.date_followup);
    const dateB =
      b.date_followup === '0000-00-00'
        ? new Date('9999-12-31')
        : new Date(b.date_followup);
    return dateB - dateA;
  });

  noDateFollowUp.sort((a, b) => {
    const dateA = new Date(a.date_creation);
    const dateB = new Date(b.date_creation);
    return dateB - dateA;
  });

  const combinedRows = [
    ...pendingRows.reverse(),
    ...closedRows.reverse(),
    ...noDateFollowUp.reverse(),
  ];

  if (loading) return <Skeleton width="100%" height={400} animation="wave" />;
  if (data.length <= 0) {
    return (
      <h2 style={{ textAlign: 'center' }}>
        <FormattedMessage id="no.notes.found" />{' '}
      </h2>
    );
  }
  if (error) {
    return (
      <h2>
        `<FormattedMessage id="AnErrorHasOcurred" />, ${error}`
      </h2>
    );
  }
  return (
    <TableContainer component={Paper}>
      {data && (
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#2D323E' }}>
              <TableCell />
              <TableCell
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
              >
                <FormattedMessage id="Description" />
              </TableCell>
              <TableCell
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
                align="right"
              >
                <FormattedMessage id="TypeOfNote" />
              </TableCell>
              <TableCell
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
                align="right"
              >
                <FormattedMessage id="Responsible" />
              </TableCell>
              <TableCell
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
                align="right"
              >
                <FormattedMessage id="DueDate" />
              </TableCell>
              <TableCell
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
                align="right"
              >
                <FormattedMessage id="Status" />
              </TableCell>
              <TableCell
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
                align="right"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedRows.map((row) => (
              <RowNotes
                key={row.id}
                row={row}
                setIsSubmitted={setIsSubmitted}
              />
            ))}
          </TableBody>
        </Table>
      )}
      {isSubmitted && <SuccessNote isSubmitted={isSubmitted} />}
    </TableContainer>
  );
};
