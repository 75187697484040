import React from 'react';
import Grid from '@material-ui/core/Grid';
import StyledCard from 'common/components/Card';
import CustomAccordion from 'common/components/Accordion/Accordion';
import Table from 'views/lookALike/components/table';
import TitleSection from 'common/components/Message/TitleSection';
import useClass from 'views/lookALike/styles';
import TabPanel from 'common/components/TabPanel/TabPanel';
import { TITLES_LOOK_LIKE_TAB } from 'common/constants/tabsTitles';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_LOOK_A_LIKE_SELECTED } from 'rdx/newRedux/selectores/keys';
import AsyncAction from 'rdx/newRedux/actions/AsyncAction';
import { LOOK_LIKE_TYPE } from 'rdx/newRedux/types';
import { useDispatch } from 'react-redux';
import ByIp from 'views/lookALike/components/byIp';
import Original from './components/original';


const Content = ({ reports, actual }) => {
  const clasess = useClass();
  const [open, setOpen] = React.useState(false);
  const [expand, setExpand] = React.useState(8);
  const selected = GetSelector(KEY_LOOK_A_LIKE_SELECTED)
  const onChange = (ac) => (event, isOpen) => {
    setOpen(isOpen ? ac : false);
    setExpand(isOpen ? 12 : 8);
  }
  React.useEffect(() => {
    setOpen(false);
    setExpand(8);
  }, [actual]);

  const listAccordion = reports && reports[actual] && reports[actual].map((item) => (
    <CustomAccordion
      title={item.domain}
      open={open}
      onChange={() => onChange(item.domain)}
    >
      <Table dnsA={item.dns_a} dnsAA={item.dns_aaaa} />
    </CustomAccordion>
  ));
  const dispatch = useDispatch();
  const onChangeTab = (val) => {
    dispatch(AsyncAction(LOOK_LIKE_TYPE, val));
  }
  return (
    <React.Fragment>
      <div style={{ marginBottom: '10px' }}>
        <TabPanel titles={TITLES_LOOK_LIKE_TAB} tabIndex={selected} setTabIndex={onChangeTab} />
      </div>
      {selected === 0
        && (
        <React.Fragment>
          <Original data={reports.original[0]} />
          {listAccordion && (
          <StyledCard padding="20px" bg="transparent">
            <Grid container>
              <Grid item md={expand} xs={12}>
                <TitleSection
                  size={18}
                  separator={false}
                  id="domain"
                  className={clasess.titleDomain}
                />
                {listAccordion}
              </Grid>
            </Grid>
          </StyledCard>
          )
          }
        </React.Fragment>
        )
      }
      {
        selected === 1 && <ByIp />
      }
    </React.Fragment>
  );
}

export default Content;
