import React from 'react';
import filter from 'lodash/filter';
import { withRouter } from 'react-router-dom'
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_ACTUAL_COMPANY, KEY_COMPANY } from 'rdx/newRedux/selectores/keys';
import { LinkButton } from 'common/components/Button/Button';
import { connect, useDispatch } from 'react-redux';
import Action from 'rdx/newRedux/actions/Action';
import { ACTUAL_URL, IS_LOADIND_DATA, SET_ACTUAL_COMPANY } from 'rdx/newRedux/types';
import DialogContainer from 'common/components/Dialogs';
import RadioButtonList from 'common/components/RadioButtonList/RadioButtonList';
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import { domainDetailsSelector } from 'rdx/summary/selectors';

const Company = ({ customerId, email, history }) => {
  const companyList = GetSelector(KEY_COMPANY);
  const actual = GetSelector(KEY_ACTUAL_COMPANY);

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const setActual = (id) => {
    const ac = filter(companyList[0].Companies, (item) => item.id === id)
    dispatch(Action(SET_ACTUAL_COMPANY, ac[0]));
  };
  React.useEffect(() => {
    if (!actual && companyList && companyList.length > 0) setActual(customerId);
  });
  const [load, setLoad] = React.useState(false);
  const list = actual && companyList
    && companyList[0].Companies
      .map((item) => ({ label: item.name, value: item.id, disabled: actual.id === item.id }));
  const onSelected = (i) => {
    setActual(i);
    sessionStorage.setItem('customId', i);
    sessionStorage.setItem('customEmail', email);
    setOpen(false);
    setLoad(true);
    if (window.location.href.includes('notes')) window.location.reload()
  }
  React.useEffect(() => {
    if (load) {
      const act = history.location.pathname;
      if (act !== '/' && act !== "'/dashboard") {
        dispatch(Action(IS_LOADIND_DATA, true));
        dispatch(Action(ACTUAL_URL, act));
        history.push('/');
        setLoad(false);
      }
    }
  }, [actual]);

  if (history) {
    if (companyList) {
      return (
        <React.Fragment>
          { actual
        && <LinkButton onClick={() => setOpen(true)}><b>{actual.name}</b></LinkButton>
          }
          <DialogContainer onClose={() => setOpen(false)} open={open}>
            <RadioButtonList
              title="select.company"
              loading={list}
              details={list}
              onSelected={(e) => onSelected(e.target.value)}
            />
          </DialogContainer>
        </React.Fragment>
      );
    }
  }
  return null
};
const mapStateToProps = (state) => ({
  domainDetails: domainDetailsSelector(state),
})
const mapDispatchToProps = {}
/*
const mapDispatchToProps = {
  loadPrecalculated: asyncActions.loadAction,
  setDisplay: selectDisplay,

}
const mapStateToProps = (state) => ({
  domain: currentDomainSelector(state),
  domainDetails: domainDetailsSelector(state),
})
export default connect(
  mapStateToProps, mapDispatchToProps
)(
  withRouter(withUserInfo(View))
 */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withUserInfo(Company)));
