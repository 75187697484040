import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import StyledCard from 'common/components/Card';
import DmarcTable from 'common/components/Table/DmarcTable';
import { TABLE_LOOK_A_LIKE_BY_IP } from 'common/constants/tabsTitles';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_LOOK_A_LIKE_BY_IP } from 'rdx/newRedux/selectores/keys';
import MessageBox from 'common/components/Message/MessageBox';
import { FormattedMessage } from 'react-intl';
import { theme } from 'src/theme';
import { orderBy } from 'lodash';

const ByIp = () => {
  const dataIp = GetSelector(KEY_LOOK_A_LIKE_BY_IP);
  const [data, setData] = useState([]);
  const [orderByKey, setOrderByKey] = React.useState('count');
  const [asc, setAsc] = React.useState('desc');
  const formatData = {
    format: {},
    styles: {
      domains: {
        style: {
          wordBreak: 'break-word',
          textAlign: 'left',
        },
      },
    },
  };
  const details = ((orderBy(data, [orderByKey], [asc])));
  const onOrderBy = (key) => {
    if (key !== orderByKey) {
      setOrderByKey(key);
    }
    if (asc === 'asc') {
      setAsc('desc');
    } else {
      setAsc('asc');
    }
  }
  useEffect(() => {
    if (dataIp) {
      const keys = Object.keys(dataIp.value);
      const act = [];
      keys.forEach((it) => {
        const dom = dataIp.value[it].domains
        // eslint-disable-next-line func-names
        dom.toString = function () {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.join(',  ')
        }
        act.push({ ip: it, count: dataIp.value[it].count, domains: dom.toString() })
      })
      setData(act)
    }
  }, [dataIp])
  if (data.length > 0) {
    return (
      <StyledCard padding="20px">
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid item>
            <DmarcTable
              titles={TABLE_LOOK_A_LIKE_BY_IP}
              details={details}
              formatData={formatData}
              onClick={onOrderBy}
              orderByKey={orderByKey}
              asc={asc}
            />
          </Grid>
        </Grid>
      </StyledCard>
    );
  }
  return (
    <MessageBox
      message={<b><FormattedMessage id="not.have.data" /></b>}
      variant="primary"
      rest={{ color: theme.colors.grey5, bg: theme.colors.blue1 }}
    />
  )
};

export default ByIp;
