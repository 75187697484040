import React from 'react';
import StyledCard from 'common/components/Card';
import TitleSection from 'common/components/Message/TitleSection';
import {
  Accordion, AccordionDetails, AccordionSummary, Grid,
} from '@material-ui/core';
import useClass from '../styles';
import Table from './table';

const Original = ({ data }) => {
  const clasess = useClass();

  return (
    <StyledCard padding="20px" bottom="20px">
      <Accordion expanded>
        <AccordionSummary
          className={clasess.titleAccordion}
          aria-controls="original-domain-bh-content"
          id="original-domain-bh-header"
        >
          <Grid container>
            <Grid item xs={12}>
              <TitleSection size={22} separator={false} id="domain" className={clasess.titleOriginalDomain} />
            </Grid>
            <Grid item xs={12}>
              <TitleSection separator={false} size={20} id={data.domain} />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Table dnsA={data.dns_a} dnsAA={data.dns_aaaa} />
        </AccordionDetails>
      </Accordion>
    </StyledCard>
  );
};

export default Original;
