// import React, { useState } from 'react'
// import SearchViewTable from 'common/components/SearchViewTable'
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
// } from '@mui/material';
// import { C46TableCell } from './C46TableCell'

// export const TableMatrizSearchFeed = ({
//   option, records, totalDetailsMessagesNumber, secondDetailsRowValues, firstDetailsRowValues,
// }) => {
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleRowClick = (index) => {
//     const selectedIndex = selectedRows.indexOf(index);
//     const newSelected = [...selectedRows];

//     if (selectedIndex === -1) {
//       newSelected.push(index);
//     } else {
//       newSelected.splice(selectedIndex, 1);
//     }

//     setSelectedRows(newSelected);
//   };

//   const isSelected = (index) => selectedRows.indexOf(index) !== -1;
//   const DetailTitles = ['Pass y SPF = Pass', 'Pass y SPF = Fail', 'Fail y SPF = Pass', 'Fail y SPF = Fail']
//   const HeadtTableTitles = ['Dkim', 'Dkim Domain', 'SPF', 'Envelope From', 'Header From', 'Dkim Alignment', 'DMARC Alignment', 'SPF Alignment',
//     'Messages Number', 'Disposition', 'Policy Evaluated Dkim', 'Policy Evaluated SPF', 'Sender', 'Country', 'IP', 'Multiples Dkim Domain']

//   return (
//     <React.Fragment>
//       <h2 style={{ color: '#3E97EB', textAlign: 'center', margin: '25px 0' }}>Details</h2>
//       <div style={{
//         backgroundColor: 'white', margin: '10px auto', borderRadius: '3px', padding: '20px', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 10px -2px, rgba(0, 0, 0, 0.06) 0px 4px 10px -2px, rgba(0, 0, 0, 0.06) 0px 4px 10px -2px',
//       }}
//       >
//         <h3 style={{ color: '#3E97EB' }}>DKIM =  {DetailTitles[option]}</h3>
//         <SearchViewTable totalMessagesNumber={totalDetailsMessagesNumber} firstRowValues={firstDetailsRowValues} secondRowValues={secondDetailsRowValues} />
//       </div>
//       <TableContainer style={{ marginLeft: '-25vw', width: '90vw', marginTop: '25px' }} component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow style={{ backgroundColor: '#3E97E8' }}>
//               {HeadtTableTitles.map((value, index) => (
//                 <TableCell style={{ color: 'white', fontWeight: 'bold' }} key={index}>{value}</TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record, index) => (
//               <TableRow
//                 key={index}
//                 onClick={() => handleRowClick(index)}
//                 selected={isSelected(index)}
//                 style={{
//                   backgroundColor: isSelected(index) ? '#e0e0e0' : 'white',
//                   cursor: 'pointer',
//                 }}
//               >
//                 {/* No se muestra la columna "C1" */}
//                 <TableCell>{record.C10}</TableCell>
//                 <TableCell>{record.C11}</TableCell>
//                 <TableCell>{record.C13}</TableCell>
//                 <TableCell>{record.C16}</TableCell>
//                 <TableCell>{record.C18}</TableCell>
//                 <TableCell>{record.C19}</TableCell>
//                 <TableCell>{record.C20}</TableCell>
//                 <TableCell>{record.C21}</TableCell>
//                 <TableCell>{record.C22}</TableCell>
//                 <TableCell>{record.C23}</TableCell>
//                 <TableCell>{record.C24}</TableCell>
//                 <TableCell>{record.C28}</TableCell>
//                 <TableCell>{record.C29}</TableCell>
//                 <TableCell>{record.C30}</TableCell>
//                 <TableCell>{record.C31}</TableCell>
//                 <C46TableCell data={record.C46} />
//                 {/* {record.C46.length > 0 && <C46TableCell data={record.C46} />} */}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           rowsPerPageOptions={[10, 25, 50]}
//           component="div"
//           count={records.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </TableContainer>

//     </React.Fragment>
//   )
// }


// import React, { Component } from 'react';
// import SearchViewTable from 'common/components/SearchViewTable';
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
// } from '@mui/material';
// import { injectIntl } from 'react-intl';
// import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';
// import { C46TableCell } from './C46TableCell';

// class TableMatrizSearchFeed extends Component {
//   render() {
//     const {
//       option, records, totalDetailsMessagesNumber, secondDetailsRowValues, firstDetailsRowValues, intl,
//     } = this.props;

//     const [selectedRows, setSelectedRows] = React.useState([]);
//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);

//     const handleChangePage = (event, newPage) => {
//       setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//       setRowsPerPage(parseInt(event.target.value, 10));
//       setPage(0);
//     };

//     const handleRowClick = (index) => {
//       const selectedIndex = selectedRows.indexOf(index);
//       const newSelected = [...selectedRows];

//       if (selectedIndex === -1) {
//         newSelected.push(index);
//       } else {
//         newSelected.splice(selectedIndex, 1);
//       }

//       setSelectedRows(newSelected);
//     };

//     const isSelected = (index) => selectedRows.indexOf(index) !== -1;
//     const DetailTitles = ['Pass y SPF = Pass', 'Pass y SPF = Fail', 'Fail y SPF = Pass', 'Fail y SPF = Fail'];
//     const HeadtTableTitles = ['Dkim', 'Dkim Domain', 'SPF', 'Envelope From', 'Header From', 'Dkim Alignment', 'DMARC Alignment', 'SPF Alignment',
//       'Messages Number', 'Disposition', 'Policy Evaluated Dkim', 'Policy Evaluated SPF', 'Sender', 'Country', 'IP', 'Multiples Dkim Domain'];

//     return (
//       <React.Fragment>
//         <h2 style={{ color: '#3E97EB', textAlign: 'center', margin: '25px 0' }}>Details</h2>
//         <div style={{
//           backgroundColor: 'white', margin: '10px auto', borderRadius: '3px', padding: '20px', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 10px -2px, rgba(0, 0, 0, 0.06) 0px 4px 10px -2px, rgba(0, 0, 0, 0.06) 0px 4px 10px -2px',
//         }}
//         >
//           <h3 style={{ color: '#3E97EB' }}>DKIM =  {DetailTitles[option]}</h3>
//           <SearchViewTable totalMessagesNumber={totalDetailsMessagesNumber} firstRowValues={firstDetailsRowValues} secondRowValues={secondDetailsRowValues} />
//         </div>
//         <TableContainer style={{ marginLeft: '-25vw', width: '90vw', marginTop: '25px' }} component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow style={{ backgroundColor: '#3E97E8' }}>
//                 {HeadtTableTitles.map((value, index) => (
//                   <TableCell style={{ color: 'white', fontWeight: 'bold' }} key={index}>{value}</TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record, index) => (
//                 <TableRow
//                   key={index}
//                   onClick={() => handleRowClick(index)}
//                   selected={isSelected(index)}
//                   style={{
//                     backgroundColor: isSelected(index) ? '#e0e0e0' : 'white',
//                     cursor: 'pointer',
//                   }}
//                 >
//                   {/* No se muestra la columna "C1" */}
//                   <TableCell>{record.C10}</TableCell>
//                   <TableCell>{record.C11}</TableCell>
//                   <TableCell>{record.C13}</TableCell>
//                   <TableCell>{record.C16}</TableCell>
//                   <TableCell>{record.C18}</TableCell>
//                   <TableCell>{record.C19}</TableCell>
//                   <TableCell>{record.C20}</TableCell>
//                   <TableCell>{record.C21}</TableCell>
//                   {/* <TableCell>{intl.formatNumber(record.C22)}</TableCell> Formatea record.C22 con intl */}
//                   {/* <TableCell>{FormatNumberESService.formatNumber(intl, record.C22)}</TableCell> */}
//                   <TableCell>{record.C23}</TableCell>
//                   <TableCell>{record.C24}</TableCell>
//                   <TableCell>{record.C28}</TableCell>
//                   <TableCell>{record.C29}</TableCell>
//                   <TableCell>{record.C30}</TableCell>
//                   <TableCell>{record.C31}</TableCell>
//                   <C46TableCell data={record.C46} />
//                   {/* {record.C46.length > 0 && <C46TableCell data={record.C46} />} */}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//           <TablePagination
//             rowsPerPageOptions={[10, 25, 50]}
//             component="div"
//             count={records.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </TableContainer>

//       </React.Fragment>
//     );
//   }
// }

// export default injectIntl(TableMatrizSearchFeed);


import React, { useState, useEffect } from 'react';
import SearchViewTable from 'common/components/SearchViewTable';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';
import TablePagination from '@material-ui/core/TablePagination';
import { C46TableCell } from './C46TableCell';

const TableMatrizSearchFeed = ({
  option, records, totalDetailsMessagesNumber, secondDetailsRowValues, firstDetailsRowValues, intl, page, setPage,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  //   const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedRows([])
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // creando media query manual
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calcula el estilo de marginLeft en función del ancho de la ventana
  const marginLeftStyle = windowWidth < 960 ? '0' : '-25vw';
  const handleRowClick = (index) => {
    const selectedIndex = selectedRows.indexOf(index);
    const newSelected = [...selectedRows];

    if (selectedIndex === -1) {
      newSelected.push(index);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedRows(newSelected);
  };


  const isSelected = (index) => selectedRows.indexOf(index) !== -1;
  const DetailTitles = ['Pass y SPF = Pass', 'Pass y SPF = Fail', 'Fail y SPF = Pass', 'Fail y SPF = Fail'];
  const HeadtTableTitles = ['Dkim', 'DKIMDomain', 'SPF', 'EnvelopeFrom', 'HeaderFrom', 'DKIMAlignment', 'DMARCAlignment', 'SPFAlignment',
    'MessagesNumber', 'Disposition', 'PolicyEvaluatedDkim', 'PolicyEvaluatedSPF', 'Sender', 'Country', 'IP', 'MultiplesDkimDomain'];

  return (
    <React.Fragment>
      <h2 style={{ color: '#3E97EB', textAlign: 'center', margin: '25px 0' }}>Details</h2>
      <div style={{
        backgroundColor: 'white', margin: '10px auto', borderRadius: '3px', padding: '20px', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 10px -2px, rgba(0, 0, 0, 0.06) 0px 4px 10px -2px, rgba(0, 0, 0, 0.06) 0px 4px 10px -2px',
      }}
      >
        <h3 style={{ color: '#3E97EB' }}>DKIM =  {DetailTitles[option]}</h3>
        <SearchViewTable totalMessagesNumber={totalDetailsMessagesNumber} firstRowValues={firstDetailsRowValues} secondRowValues={secondDetailsRowValues} />
      </div>
      <TableContainer
        style={{
          marginLeft: marginLeftStyle,
          width: '90vw',
          marginTop: '25px',
        }}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#3E97E8' }}>
              {HeadtTableTitles.map((value, index) => (
                <TableCell style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }} key={index}><FormattedMessage id={value} /></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(index)}
                selected={isSelected(index)}
                style={{
                  backgroundColor: isSelected(index) ? '#e0e0e0' : 'white',
                  cursor: 'pointer',
                }}
              >
                {/* No se muestra la columna "C1" */}
                <TableCell>{record.C10}</TableCell>
                <TableCell>{record.C11}</TableCell>
                <TableCell>{record.C13}</TableCell>
                <TableCell>{record.C16}</TableCell>
                <TableCell>{record.C18}</TableCell>
                <TableCell>{record.C19}</TableCell>
                <TableCell>{record.C20}</TableCell>
                <TableCell>{record.C21}</TableCell>
                <TableCell>{FormatNumberESService.formatNumber(intl, record.C22)}</TableCell>
                <TableCell>{record.C23}</TableCell>
                <TableCell>{record.C24}</TableCell>
                <TableCell>{record.C28}</TableCell>
                <TableCell>{record.C29}</TableCell>
                <TableCell>{record.C30}</TableCell>
                <TableCell>{record.C31}</TableCell>
                {record.C46.length > 0 && <C46TableCell data={record.C46} />}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={records.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}

export default injectIntl(TableMatrizSearchFeed);
