import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { NotesTooltipMenu } from 'common/components/NotesTooltipMenu';
import { EditNoteModal } from 'views/riskmatrix/notes/EditNoteModal';
import { CreateFollowUpNote } from 'common/components/CreateFollowUpNote'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { EditFollowupModal } from 'common/components/EditFollowupModal';
import { KEY_ACTUAL_COMPANY } from 'rdx/newRedux/selectores/keys';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

export const RowNotes = (props) => {
  const { row, setIsSubmitted } = props;
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [openFollowUpModal, setOpenFollowUpModal] = useState(false)
  const [openEditFollowUpModal, setOpenEditFollowUpModal] = useState(false)
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const isAdmin = noteData.payload['custom:Administrador'];
  const actualName = GetSelector(KEY_ACTUAL_COMPANY);
  let secuence = 0;
  if (row.followup_notes[0].sequence !== null) {
    secuence = row.followup_notes.length;
  } else {
    secuence = 0;
  }
  const handleEditFollowupModal = () => {
    setOpenEditFollowUpModal(!openEditFollowUpModal)
  }
  const handleModal = () => {
    setOpenModal(!openModal)
  }
  const handleFollowUpModal = () => {
    setOpenFollowUpModal(!openFollowUpModal)
  }
  const background = row.status === 'P' ? '#3E97E8' : 'rgb(35, 125, 205)';
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ backgroundColor: background }}>
        <TableCell>
          {row.followup_notes[0].sequence !== null && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) }
        </TableCell>
        <TableCell component="th" scope="row" style={{ color: 'white', fontSize: '16px' }}>{row.description}</TableCell>
        <TableCell align="right" style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>
          {row.note_type === 'U' ? <FormattedMessage id="Urgent" /> : 'Normal'}
        </TableCell>
        <TableCell align="right" style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>{row.responsable === 'C' ? actualName.name : 'DmarcGuardian'}</TableCell>
        <TableCell
          align="right"
          style={{
            whiteSpace: 'nowrap', textAlign: 'center', color: 'white', fontSize: '16px',
          }}
        >{row.date_followup.split('-').shift().trim() > 1 && row.date_followup}
        </TableCell>
        <TableCell align="right" style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>{row.status === 'P' ? <FormattedMessage id="Pending" /> : <FormattedMessage id="Closed" /> }</TableCell>
        <TableCell
          align="right"
          style={{
            width: '55px', padding: '12px',
          }}
        >
          {isAdmin === 'Y' && row.status === 'P' && <NotesTooltipMenu handleModal={handleModal} handleFollowUpModal={handleFollowUpModal} setIsSubmitted={setIsSubmitted} data={row} />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                <FormattedMessage id="FollowUp" />
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{
                      fontWeight: 'bold', fontSize: '15px', textAlign: 'center',
                    }}
                    ><FormattedMessage id="Description" />
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 'bold', fontSize: '15px', textAlign: 'center',
                      }}
                      align="right"
                    ><FormattedMessage id="User" />
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 'bold', fontSize: '15px', textAlign: 'center',
                      }}
                      align="right"
                    ><FormattedMessage id="Responsible" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.followup_notes.map((item) => (
                    <TableRow key={item.sequence}>
                      <TableCell component="th" scope="row">
                        {item.description}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }} align="right">{item.user}</TableCell>
                      <TableCell style={{ textAlign: 'center' }} align="right">{item.responsable === 'C' ? actualName.name : 'DmarcGuardian'}</TableCell>
                      {isAdmin === 'Y' && row.status === 'P' && (
                      <TableCell
                        align="right"
                      >
                        <button
                          type="button"
                          style={{
                            color: 'white',
                            backgroundColor: '#3E97E8',
                            borderRadius: '3px',
                            border: 'navajowhite',
                            cursor: 'pointer',
                          }}
                          onClick={handleEditFollowupModal}
                        >
                          <EditNoteIcon size="small" />
                        </button>
                      </TableCell>
                      )}
                      {openEditFollowUpModal && <EditFollowupModal openFollowUpModal={openEditFollowUpModal} setIsSubmitted={setIsSubmitted} id={row.id} data={item} onClose={() => setOpenEditFollowUpModal(false)} />}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {openModal && <EditNoteModal data={row} openModal={openModal} setIsSubmitted={setIsSubmitted} onClose={() => setOpenModal(false)} />}
      {openFollowUpModal && <CreateFollowUpNote openFollowUpModal={openFollowUpModal} setIsSubmitted={setIsSubmitted} secuence={secuence} id={row.id} onClose={() => setOpenFollowUpModal(false)} />}
    </React.Fragment>
  );
}
