import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormattedMessage, injectIntl } from 'react-intl';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_LANGUAGE } from 'rdx/newRedux/selectores/keys';
import { IconButton } from '@material-ui/core';
import { EventNote } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton';
import Font from 'common/components/Font';
import StyledCard from 'common/components/Card';
import useStyles from './styles';

const localLanguage = {
  es: esLocale,
  'en-US': enLocale,
}
const Datepicker = ({
  onSelected = () => null, views = [], autoOk = false,
  clearable = false, visible = true, loading = false, title = true,
}) => {
  const classes = useStyles();
  const [actual, setActual] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const onChange = (e) => {
    setActual(e);
    onSelected(e)
  }
  const setVisible = () => {
    if (visible) return true;
    if (actual) return true;
    return !actual && open;
  }
  const language = GetSelector(KEY_LANGUAGE);
  if (loading) {
    return (
      <StyledCard top="10px" padding="20px">
        <Skeleton height={18} width="50%" />
        <Skeleton variant="rect" height={30} width="70%" />
      </StyledCard>
    )
  }
  return (
    <StyledCard top="10px" padding="20px">
      {title && (
      <Font variant="h3" component="h2" className={classes.titleDatePicker}>
        <FormattedMessage id="select.year.month" />
      </Font>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localLanguage[language]}>
        <div className={classes.contentDatepicker}>
          {setVisible() && (
          <div className={classes.datepicker}>
            <DatePicker
              clearable={clearable}
              autoOk={autoOk}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              views={views}
              value={actual}
              onChange={onChange}
              disableFuture
              inputAdornmentProps={{ position: 'end' }}
              initialFocusedDate={null}
            />
          </div>
          )
        }
          <IconButton onClick={() => setOpen(!open)}>
            <EventNote color="primary" fontSize="large" />
          </IconButton>
        </div>
      </MuiPickersUtilsProvider>
    </StyledCard>
  );
};

export default injectIntl(Datepicker);
