import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { UpdateNote } from 'servicio/FetchUpdateNote'
import { KEY_ACTUAL_COMPANY } from 'rdx/newRedux/selectores/keys';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5),
    minWidth: '400px',
    minHeight: '40vh',
    border: 'none',
    borderRadius: '5px',
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sendButton: {
    margin: '20px auto',
    width: '60%',
    '&:hover': {
      color: '#3E97E8',
    },
  },
  customButton: {
    background: '#3E97E8',
    width: '100%',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      color: '#3E97E8',
    },
  },
  formGroup: {
    justifyContent: 'center',
    marginTop: '15px',
    marginBottom: '15px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute !important',
    top: '10px',
    right: '10px',
  },
}));

export const CreateFollowUpNote = ({
  openFollowUpModal, onClose, secuence, id, setIsSubmitted,
}) => {
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const classes = useStyles();
  const [nota, setNota] = useState('');
  const [responsable, setResponsable] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formValid, setFormValid] = useState(true);
  const token = noteData.jwtToken;
  const user = noteData.payload.email;
  const actualName = GetSelector(KEY_ACTUAL_COMPANY);
  const handleChangeResponsable = (event) => {
    setResponsable(event.target.value);
  };

  const currentDate = new Date();
  const dateCreationDate = currentDate.toISOString().split('T')[0];
  const currentTime = currentDate.toLocaleTimeString([], { hour12: false })
  const url = 'https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/v1/notes/insert-notes';
  const method = 'PUT';

  const handleSend = async () => {
    if (!nota || !responsable) {
      setFormValid(false);
      return;
    }
    try {
      setLoading(true);
      setErrorMessage(null);
      setIsSubmitted(false)
      const requestData = {
        type_insert: 'C',
        description: nota,
        user,
        sequence: secuence + 1,
        id_parent: id,
        responsable: responsable === 'cliente' ? 'C' : 'D',
        date_creation: dateCreationDate,
        time_creation: currentTime,
      };
      await UpdateNote({
        requestData, token, method, url,
      });
      setLoading(false);
      setIsSubmitted(true)
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <Modal
      open={openFollowUpModal}
      onClose={onClose}
      className={classes.modal}
    >
      <div className={classes.paper}>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <h2 style={{ color: '#3E97E8', textAlign: 'center' }}><FormattedMessage id="create.note" /></h2>
        <TextField
          id="filled-multiline-static"
          required
          label={<FormattedMessage id="note" />}
          multiline
          minRows={4}
          variant="filled"
          fullWidth
          value={nota}
          onChange={(e) => setNota(e.target.value)}
          error={!formValid && !nota}
          helperText={!formValid && !nota ? <FormattedMessage id="FieldsRequired" /> : ''}
        />
        <FormGroup row className={classes.formGroup}>
          <FormControl className={classes.formControl}>
            <InputLabel><FormattedMessage id="Responsible" /></InputLabel>
            <Select
              value={responsable}
              onChange={handleChangeResponsable}
              required
              error={!formValid && !responsable}
            >
              <MenuItem value="cliente">{actualName.name}</MenuItem>
              <MenuItem value="DmarcGuardian">DmarcGuardian</MenuItem>
            </Select>
            {!formValid && !responsable && <div style={{ color: 'red' }}><FormattedMessage id="FieldsRequired" /></div>}
          </FormControl>

        </FormGroup>
        <Box className={classes.sendButton}>
          <Button variant="contained" onClick={handleSend} className={classes.customButton}>
            {loading ? <CircularProgress style={{ color: 'white' }} size={22} /> : <FormattedMessage id="Send" />}
          </Button>
        </Box>
        {errorMessage && !loading && (
          <div style={{ color: 'red', textAlign: 'center' }}><FormattedMessage id="AnErrorHasOcurred" />, {errorMessage}</div>
        )}
      </div>
    </Modal>
  );
};
