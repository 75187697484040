import {
  HISTORICAL_DNS,
  HISTORICAL_DNS_ERROR,
  HISTORICAL_DNS_SUCCESS,
} from 'rdx/newRedux/types';

const historicalDns = (state = {}, { type, payload }) => {
  const response = {
    [HISTORICAL_DNS]: { loading: true },
    [HISTORICAL_DNS_SUCCESS]: { loading: false, data: payload },
    [HISTORICAL_DNS_ERROR]: { loading: false, error: payload },
  }
  return { ...state, ...response[type] } || state;
}
export default historicalDns;
