import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import NavigationList from 'common/components/Navigation/NavigationList';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const data = [
  { url: '/search', label: 'byIP', icon: TravelExploreIcon },
];
const icon = SearchIcon;
const title = 'Search';
const SearchListItem = () => <NavigationList icon={icon} data={data} title={title} />

export default SearchListItem;
