import React from 'react';
import { groupBy } from 'lodash'
import Layout from 'common/components/Layout';
import { asyncActions } from 'rdx/summary/actions';
import { currentDomainSelector, domainDetailsSelector } from 'rdx/summary/selectors';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import isEmpty from 'lodash/isEmpty';
import { Scroll } from 'common/utils/services/scroll.service';
import dataApi from 'rdx/newRedux/api/dataApi';
import Action from 'rdx/newRedux/actions/Action';
import { HISTORICAL_DNS } from 'rdx/newRedux/types';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_HISTORICAL_DNS, KEY_HISTORICAL_DNS_LOADING } from 'rdx/newRedux/selectores/keys';
import Grid from '@material-ui/core/Grid';
import TitleSection from 'common/components/Message/TitleSection';
import Domains from 'views/riskmatrix/Domains';
import Container from 'common/components/FlexContainer/Container';
import SkeletoLoading from 'common/components/Skeleton/SkeletoLoading';
import MessageBox from 'common/components/Message/MessageBox';
import { FormattedMessage } from 'react-intl';
import { theme } from 'src/theme';
import Content from 'views/reports/historicalDns/Content';

const View = ({
  customerId, loadPrecalculated, domainDetails, domain, email,
}) => {
  const dispatch = useDispatch();
  const data = GetSelector(KEY_HISTORICAL_DNS);
  const loading = GetSelector(KEY_HISTORICAL_DNS_LOADING);
  React.useLayoutEffect(() => {
    if (domain) {
      const payload = {
        params: {
          domain,
        },
        ...dataApi.getHistoricalDns,
      }
      dispatch(Action(HISTORICAL_DNS, payload));
    }
  }, [domain]);
  React.useEffect(() => {
    if (customerId && isEmpty(domainDetails)) loadPrecalculated({ customerId, email });
    Scroll.scrollTop()
  }, [customerId]);
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <TitleSection id="reports.historicaldns" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Domains type="historical-dns" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Container marginTop={64}>
            {
              loading && <SkeletoLoading />
            }
            {!loading && data && data.length === 0 && (
              <MessageBox
                message={<b><FormattedMessage id="not.have.data" /></b>}
                variant="primary"
                rest={{
                  color: theme.colors.grey5,
                  bg: theme.colors.blue1,
                }}
              />
            )}
            {
              !loading && data && <Content data={groupBy(data, 'date_changed')} />
            }
          </Container>
        </Grid>
      </Grid>
    </Layout>
  );
};

const mapDispatchToProps = {
  loadPrecalculated: asyncActions.loadAction,

}
const mapStateToProps = (state) => ({
  domain: currentDomainSelector(state),
  domainDetails: domainDetailsSelector(state),
})
export default connect(
  mapStateToProps, mapDispatchToProps,
)(
  withRouter(withUserInfo(View)),
);
