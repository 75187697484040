// import React from 'react'
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
// } from '@mui/material';
// import { FormattedMessage } from 'react-intl';

// export const SearchViewTable = ({ totalMessagesNumber, firstRowValues, secondRowValues }) => {
//   const tableValues = ['Total de mensajes alignment', '% DMARC alignment', 'Total SPF Pass',
//     '% SPF Pass', 'Total SPF alignment', '% SPF alignment', 'Total DKIM Pass', '% DKIM Pass',
//     'Total DKIM alignment', '% DKIM alignment ']
//   return (
//     <React.Fragment>
//       <h3>Cantidad total de mensaje: {totalMessagesNumber}</h3>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow style={{ backgroundColor: '#3E97E8' }}>
//               {firstRowValues.map((_, index) => (
//                 <TableCell style={{ color: 'white', fontWeight: 'bold' }} key={index}>{tableValues[index]}</TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow>
//               {firstRowValues.map((value, index) => (
//                 <TableCell key={index}>{value}</TableCell>
//               ))}
//             </TableRow>
//             <TableRow style={{ backgroundColor: '#3E97E8' }}>
//               {secondRowValues.map((_, index) => (
//                 <TableCell style={{ color: 'white', fontWeight: 'bold' }} key={index}>{tableValues[index + 5]}</TableCell>
//               ))}
//             </TableRow>
//             <TableRow>
//               {secondRowValues.map((value, index) => (
//                 <TableCell key={index}>{value}</TableCell>
//               ))}
//             </TableRow>
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </React.Fragment>

//   )
// }


import React, { Component } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';

class SearchViewTable extends Component {
  render() {
    const {
      totalMessagesNumber, firstRowValues, secondRowValues, intl,
    } = this.props;
    const tableValues = ['TotalMessageAlignment', '%DMARCAlignment', 'TotalSPFPsss',
      '%SPFPass', 'TotalSPFAlignment', '%SPFAlignment', 'TotalDKIMPass', '%DKIMPass',
      'TotalDKIMAlignment', '%DKIMAlignment'];

    const formattedFirstRowValues = firstRowValues.map((value, index) => {
      const formattedValue = FormatNumberESService.formatNumber(intl, value);
      const isPercentageValue = tableValues[index].includes('%');
      const formattedWithPercentage = isPercentageValue ? `${formattedValue}%` : formattedValue;
      return intl.locale === 'es' ? formattedWithPercentage.replaceAll(',', '.') : formattedWithPercentage.replace('.', ',');
    });

    const formattedSecondRowValues = secondRowValues.map((value, index) => {
      const formattedValue = FormatNumberESService.formatNumber(intl, value);
      const isPercentageValue = tableValues[index + 5].includes('%');
      const formattedWithPercentage = isPercentageValue ? `${formattedValue}%` : formattedValue;
      return intl.locale === 'es' ? formattedWithPercentage.replaceAll(',', '.') : formattedWithPercentage.replace('.', ',');
    });


    return (
      <React.Fragment>
        <h3><FormattedMessage id="totalMessageAmount" />{FormatNumberESService.formatNumber(intl, totalMessagesNumber)}</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#3E97E8' }}>
                {firstRowValues.map((_, index) => (
                  <TableCell style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }} key={index}>
                    <FormattedMessage id={tableValues[index]} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {formattedFirstRowValues.map((value, index) => (
                  <TableCell style={{ textAlign: 'center' }} key={index}>{value}</TableCell>
                ))}
              </TableRow>
              <TableRow style={{ backgroundColor: '#3E97E8' }}>
                {secondRowValues.map((_, index) => (
                  <TableCell style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }} key={index}>
                    <FormattedMessage id={tableValues[index + 5]} />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {formattedSecondRowValues.map((value, index) => (
                  <TableCell style={{ textAlign: 'center' }} key={index}>{value}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }
}

export default injectIntl(SearchViewTable);
