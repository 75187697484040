import * as React from 'react';
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from '@mui/icons-material/Info';
import { FormattedMessage } from 'react-intl';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  // Filtrar reportes duplicados
  const uniqueReports = row[1].reports.filter((item, index, self) => index === self.findIndex((i) => i['report-id'] === item['report-id']));
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ backgroundColor: '#3E97E8' }}>
        <TableCell style={{ color: 'white' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }}>
          <FormattedMessage id="date" />: {row[0]}
        </TableCell>
        <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }}><FormattedMessage id="total.messages" />: {uniqueReports.reduce((suma, item) => suma + item.total_msg, 0)}</TableCell>
        <TableCell align="center" style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }}><FormattedMessage id="total.reports" />: {uniqueReports.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}><FormattedMessage id="report.id" /></TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}><FormattedMessage id="messages.amount" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uniqueReports.map((item) => (
                    <TableRow key={item['report-id']}>
                      <TableCell align="center" scope="row">
                        {item['report-id']}
                      </TableCell>
                      <TableCell align="center">{item.total_msg}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const ReportsTable = ({ data }) => {
  const results = Object.entries(data.results).reverse()
  const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);


  useEffect(() => {
    // Mostrar el mensaje de "No se encontraron resultados" con un retraso de 1 segundo
    const timeout = setTimeout(() => {
      if (results.length <= 0) {
        setShowNoResultsMessage(true);
      }
    }, 5000);

    // Limpiar el timeout al desmontar el componente o al cambiar los resultados
    return () => clearTimeout(timeout);
  }, [data]);

  return (
    <div>
      {!showNoResultsMessage && results.length > 0 && (
        <p style={{ padding: '0 10px', textAlign: 'center', fontSize: '15px' }}>
          <InfoIcon size="small" style={{ color: 'goldenrod', verticalAlign: 'middle', marginRight: '5px' }} />
          <FormattedMessage id="informative.message.reports1" /> {data.date_ini} <FormattedMessage id="informative.message.reports2" />
        </p>
      )
    }
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            {results.map((row) => (
              <Row key={row[0]} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showNoResultsMessage && results.length <= 0 && (
      <h3 style={{ textAlign: 'center', marginTop: '20px' }}>No se encontraron resultados.</h3>
      )}

    </div>
  );
}
