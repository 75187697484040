import styled from 'styled-components';
import Card from 'common/components/Card/Card';
import { theme } from 'src/theme';

export const ContainerCardRadio = styled(Card)`
  padding: 20px;
`;

export const ItemsRadioList = styled.div`
  margin-top: 10px;
  border-radius: 10px;
  max-width: 300px;
  text-decoration: none;
  padding-left: 10px;
`;

export const RangeSelectorContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardItem = styled(Card)((props) => (`
    &.MuiCard-root {
      background-color: ${props.container ? theme.colors.blue1 : '#FFF'};
      color: ${props.container ? '#FFF' : theme.colors.blue1};
      cursor: pointer;
      min-height: 100px;
      transition: 0.8s background-color, 0.6s height;
      &:hover {
      background-color: ${theme.colors.blue1};
      color: #FFF;
      min-height: 110px;
      }
      .title {
        background-color: ${theme.colors.blue1};
        color: #FFF;
        font-size: 16px;
        padding: 10px;
        text-align: center;
        border-bottom: ${props.over || props.container ? '0.5px solid #FFF' : 'none'};
      }
      .content {
        font-size: ${props.container ? '25px' : '20px'};
        text-align: center;
        padding: 10px;
      }

      .alternative {
        font-size: ${props.container ? '30px' : '25px'};
        text-align: center;
        padding-bottom: 10px;
      }
    }
`));
export const TableCont = styled.div((props) => (`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${props.top ? props.top : '0'};
; `));
