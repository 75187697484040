import React, { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { FormattedMessage } from 'react-intl';
import { AccordionNote } from '../AccordionNote';

export const SkeletonLoading = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rounded" width="100%" height={50} />
      <Skeleton variant="rounded" width="100%" height={50} />
    </Stack>
  );
};

const CompanyNotesLogic = ({
  noteData, timeRange, actualId, isNoteSubmitted,
}) => {
  const { id } = actualId;
  const token = noteData.jwtToken;
  const type = timeRange;
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const url = `https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/v1/general-notes/client/${id}/${type}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            Accept: 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Error en la solicitud');
        }

        const responseData = await response.json();
        const sortedNotes = responseData.values.sort((a, b) => {
          const dateA = new Date(a.date_created);
          const dateB = new Date(b.date_created);
          return dateB - dateA;
        });

        if (isMounted) {
          setNotes(sortedNotes);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Función de limpieza para indicar que el componente se ha desmontado
    };
  }, [id, token, type, isNoteSubmitted]);

  return { notes, isLoading, error };
};

export const CompanyNotes = (props) => {
  const { notes, isLoading, error } = CompanyNotesLogic(props);


  const containerStyle = {
    minWidth: '100%',
    margin: '0',
    backgroundColor: 'white',
    height: '200px',
    borderRadius: '4px',
    padding: '20px 0px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  }
  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (error) {
    return <Box sx={containerStyle}> <Typography sx={{ color: 'red', fontSize: '1.1rem', textAlign: 'center' }}>Error: {error}</Typography></Box>;
  }
  if (notes.length <= 0) return <Box sx={containerStyle}> <Typography sx={{ color: 'black', fontSize: '1.1rem', textAlign: 'center' }}><FormattedMessage id="no.notes.found" /></Typography></Box>
  return (
    <div>
      {notes.map((value, index) => (
        <AccordionNote key={index} index={index} value={value} />
      ))}
    </div>
  );
};
