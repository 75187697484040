import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

export const TimeRangeSelector = ({ onChange, state }) => {
  const isMediumScreen = useMediaQuery('(max-width: 1024px)');
  const handleChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    // Llamar a la función onChange con el valor seleccionado
    onChange(selectedValue);
  };

  return (
    <Box sx={{ marginTop: '-20px' }}>
      <Typography
        variant="h2"
        sx={{
          fontSize: '40px', color: '#3E97E8', fontWeight: 'bold', fontFamily: "'Muli',sans-serif !important",
        }}
      >
        <FormattedMessage id="general.notes" />
      </Typography>
      <Box
        sx={{
          minWidth: 200,
          marginTop: '44px',
          padding: '25px',
          backgroundColor: 'white',
          width: isMediumScreen ? '71vw' : 'auto',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        }}
      >
        <FormControl fullWidth>
          <FormLabel id="demo-radio-buttons-group-label">
            <FormattedMessage id="filters" />
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="Mes actual"
            value={state.toString()}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={<FormattedMessage id="current.month" />}
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={<FormattedMessage id="last.month" />}
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={<FormattedMessage id="two.months.ago" />}
            />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label={<FormattedMessage id="three.months.ago" />}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
