import React from 'react';
import IdleTimer from 'react-idle-timer';

import { Auth } from 'aws-amplify';
import { SESSION_TIMEOUT } from 'common/constants/constants';
import { useDispatch } from 'react-redux';

const ExpireSession = ({ history, logoutUser }) => {
  const idleTimerRef = React.useRef(null);
  const dispatch = useDispatch();
  const onHidle = async () => {
    try {
      await Auth.signOut();
      dispatch(logoutUser());
      history.push('/');
    } catch (e) {
      console.info('logout');
    }
  }
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={SESSION_TIMEOUT}
        onIdle={onHidle}
      />
    </div>
  );
};

export default ExpireSession;
