import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const Content = () => (
  <Grid container spacing={2}>
    <Grid item md={1}>
      <Skeleton circle="true" width={10} height={10} />
    </Grid>
    <Grid item md={8}>
      <Skeleton height={10} text="true" />
    </Grid>
  </Grid>
);
const SkeletonRadioButton = ({ length = 3 }) => {
  return (
    <React.Fragment>
      { Array.from(Array(length).keys()).map(() => <Content key={uniqueId()} />)}
    </React.Fragment>
  )
};

export default SkeletonRadioButton;
