import {
  ACTUAL_URL,
  ERROR_ONLOAD_MATRIZ,
  ERROR_ONLOAD_MATRIZ_LOADINNG,
  IS_LOADIND_DATA,
} from 'rdx/newRedux/types';

export const utils = (state = { loading: false, errorMatriz: false }, { type, payload }) => {
  const result = {
    [IS_LOADIND_DATA]: { loading: payload },
    [ACTUAL_URL]: { url: payload },
    [ERROR_ONLOAD_MATRIZ]: { errorMatriz: payload },
    [ERROR_ONLOAD_MATRIZ_LOADINNG]: { loadMatriz: payload },

  }
  return { ...state, ...result[type] } || { ...state };
}
