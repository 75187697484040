import React from 'react';
import { orderBy, keys } from 'lodash';
import CustomAccordion from 'common/components/Accordion/Accordion';
import StyledCard from 'common/components/Card';
import Grid from '@material-ui/core/Grid';
import Item from 'views/reports/historicalDns/components/Item';

const Content = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [expand, setExpand] = React.useState(5);
  const onChange = (ac) => (event, isOpen) => {
    setOpen(isOpen ? ac : false);
    setExpand(isOpen ? 12 : 5);
  }
  const listAccordion = data && keys(data).map((item) => (
    <CustomAccordion
      title={item}
      open={open}
      onChange={() => onChange(item)}
      titleTranslate={false}
      key={item}
    >
      <React.Fragment>
        <Item data={data && orderBy(data[item], 'newest_pos')} />
      </React.Fragment>
    </CustomAccordion>
  ));

  return (
    <React.Fragment>
      <StyledCard padding="20px" bg="transparent">
        <Grid container>
          <Grid item md={expand} xs={12}>
            {data && listAccordion}
          </Grid>
        </Grid>
      </StyledCard>
    </React.Fragment>
  );
};

export default Content;
