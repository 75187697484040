export const style = {
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    alignItems: 'center',
    background: 'rgb(62, 151, 232)',
    color: 'white',
    display: 'flex',
    fontSize: '25px',
    height: 40,
    justifyContent: 'center',
    marginBottom: '10px',
    padding: '10px',
    width: '300px',
  },
}
