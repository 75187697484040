export const UpdateNote = async ({
  requestData, token, method, url,
}) => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Error al actualizar la nota');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
