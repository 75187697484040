import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MessageBox from 'common/components/Message/MessageBox';
import { theme } from 'src/theme';
import Bar from 'views/reports/histogram/Bar/Bar';

const useStyles = makeStyles(() => ({
  button: {
    height: 30,
  },
  content: {
    marginBottom: 20,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: '0px 4px 10px -2px rgb(0 0 0 / 6%), 0px 4px 10px -2px rgb(0 0 0 / 6%), 0px 4px 10px -2px rgb(0 0 0 / 6%)',
  },
}));
const Details = ({
  intl,
  data,
}) => {
  const clasess = useStyles();
  const keyTitles = {
    dmarc: 'dashboard.summary.dmarcPass',
    spf: 'spf.align',
    dkim: 'dkim.align',

  }
  const titles = {
    vAxis: {
      title: intl.formatMessage({ id: 'percentage' }),
      format: '#\'%\'',
      minValue: 0,
      maxValue: 100,
    },
    hAxis: {
      title: intl.formatMessage({ id: 'date' }),
    },
  }
  const initialData = {
    limit: [
      '',
      intl.formatMessage({ id: 'percentage.by.date' }),
      intl.formatMessage({ id: 'limit' }),
    ],
    notLimit: [
      '',
      intl.formatMessage({ id: 'percentage.by.date' }),
    ],
  };
  const setData = (da) => {
    const keys = Object.keys(da)
      .filter((x) => x !== 'dates')
    const d = {};
    keys.forEach((l) => {
      d[l] = {
        data: [],
        titles,
      }
      const withLimit = da[l].limit > 0;
      da[l].value.forEach((el, index) => {
        const date = da.dates[index];
        if (withLimit) {
          d[l].data.push([`${date.year}-${date.month}`, el, da[l].limit])
        } else {
          d[l].data.push([`${date.year}-${date.month}`, el])
        }
      });
      d[l].data = d[l].data.reverse();
      if (withLimit) {
        d[l].data.unshift(initialData.limit)
      } else {
        d[l].data.unshift(initialData.notLimit)
      }
    })
    return {
      keys,
      d,
    };
  }
  if (data && data.dates && data.dates.length > 0) {
    const {
      keys,
      d,
    } = setData(data);
    return (
      <React.Fragment>
        {
          keys.map((k) => (
            <div key={k} className={clasess.content}>
              <Bar
                data={d[k].data}
                titles={{
                  ...d[k].titles,
                  title: intl.formatMessage({ id: keyTitles[k] }),
                }}
              />
            </div>
          ))
        }
      </React.Fragment>
    )
  }
  return (
    <MessageBox
      message={<b><FormattedMessage id="not.have.data" /></b>}
      variant="primary"
      rest={{
        color: theme.colors.grey5,
        bg: theme.colors.blue1,
      }}
    />
  )
}

export default injectIntl(Details);
