import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { TimeRangeSelector } from 'common/components/TimeRangeSelector';
import Layout from 'common/components/Layout';
import { NotesCreation } from 'common/components/NotesCreation';
import { CompanyNotes } from 'common/components/CompanyNotes';
import { useSelector } from 'react-redux';
import { ToggleNotesCreation } from 'common/components/ToggleNotesCreation';
import { KEY_ACTUAL_COMPANY } from 'rdx/newRedux/selectores/keys';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';

import { Fade } from '@mui/material';

export const NotesFeed = () => {
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const [timeRange, setTimeRange] = useState(0);
  const [isNotesCreationVisible, setIsNotesCreationVisible] = useState(false);
  const actualId = GetSelector(KEY_ACTUAL_COMPANY)
  const [admin, setAdmin] = useState('N');
  const [isNoteSubmitted, setIsNoteSubmitted] = useState(false);

  const handleToggleNotesCreation = () => {
    setIsNotesCreationVisible(!isNotesCreationVisible);
  };

  const handleTimeRangeChange = (selectedTimeRange) => {
    setTimeRange(selectedTimeRange);
  };
  // useEffect(() => {
  //   // Recargar la página al montar el componente
  //   console.log('Recargando')
  //   window.location.reload();
  // }, []);

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery('(max-width: 1024px)');
  const isLargeScreen = useMediaQuery('(max-width: 1400px)');

  useEffect(() => {
    if (noteData) {
      setAdmin(noteData.payload['custom:Administrador']);
    }
  }, [noteData]);
  return (
    <Layout>
      {admin === 'Y' && (
        <ToggleNotesCreation
          handleToggleNotesCreation={handleToggleNotesCreation}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'self-start',
          flexWrap: 'wrap',
          justifyContent: isMediumScreen ? 'center' : 'space-between',
        }}
      >
        <TimeRangeSelector onChange={handleTimeRangeChange} state={timeRange} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            maxWidth: isSmallerScreen
              ? '90vw'
              : isMediumScreen
                ? '75vw'
                : isLargeScreen
                  ? '60vw'
                  : '50vw',
            minWidth: isSmallerScreen
              ? '70vw'
              : isMediumScreen
                ? '65vw'
                : isLargeScreen
                  ? '50vw'
                  : '40vw',
            margin: '0 auto',
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            align="center"
            sx={{
              fontFamily: "'Muli',sans-serif",
              fontSize: '22px',
              fontWeight: 'bold',
              margin: '20px 0 25px',
              color: '#3E97E8',
            }}
          >
            <FormattedMessage id="notes" />
          </Typography>
          {noteData && actualId && (
            <CompanyNotes noteData={noteData} timeRange={timeRange} actualId={actualId} isNoteSubmitted={isNoteSubmitted} />
          )}
          {noteData && actualId && (
            <Fade in={isNotesCreationVisible} timeout={600}>
              <div><NotesCreation noteData={noteData} actualId={actualId} setIsNoteSubmitted={setIsNoteSubmitted} /></div>
            </Fade>
          )}
        </Box>
      </Box>
    </Layout>
  );
};
