export const TITLES_TYPE1 = {
  repMenSpfPassAlgTrue: 'total.messages.spf.valid.align.valid',
  repMenSpfPassAlgFalse: 'total.messages.spf.valid.align.not.valid',
  repMenSpfNotPassAlgTrue: 'total.messages.spf.not.valid.align.valid',
  repMenSpfNotPassAlgFalse: 'total.messages.spf.not.valid.align.not.valid',
  repMenDkimPassAlgTrue: 'total.messages.dkim.valid.align.valid',
  repMenDkimPassAlgFalse: 'total.messages.dkim.valid.align.not.valid',
  repMenDkimNotPassAlgTrue: 'total.messages.dkim.not.valid.align.valid',
  repMenDkimNotPassAlgFalse: 'total.messages.dkim.not.valid.align.not.valid',
  repMenDmarcPass: 'total.messages.dmarc.pass',
  repMenTotalMensajes: 'total.messages.recive',
}
export const dummy = [];
