import React from 'react';
import Card from 'common/components/Card';
import Grid from '@material-ui/core/Grid';
import TitleSection from 'common/components/Message/TitleSection';
import { makeStyles } from '@material-ui/core';
import { theme } from 'src/theme';
import styled from 'styled-components';
import DmarcTable from 'common/components/Table/DmarcTable';
import Separator from 'common/components/Separator';
import MessageBox from 'common/components/Message/MessageBox';
import { FormattedMessage } from 'react-intl';

const TableCont = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
overflow: auto;
; `;


const common = {
  minHeight: 200,
  maxHeight: 600,
  padding: 10,
  marginBottom: 10,
}
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  content: {
    justifyContent: 'space-between',
  },
  card: {
    ...common,
  },
  cardScroll: {
    ...common,
    overflowY: 'scroll',
  },
  list: {
    border: `1px solid ${theme.colors.blue1}`,
    display: 'flex',
    maxWidth: '90vh',
  },
  listTitle: {
    textTransform: 'capitalize',
    backgroundColor: theme.colors.blue1,
    color: theme.colors.grey5,
    minWidth: '4vw',
    padding: 5,
    borderBottom: `1px solid ${theme.colors.grey5}`,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  listContent: {
    padding: 5,
    wordBreak: 'break-all',
  },
}));

const Custom = ({ actual, children, scroll = false }) => {
  const clasess = useStyles();
  return (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Card className={!scroll ? clasess.card : clasess.cardScroll}>
        <TitleSection translate={false} id={actual} size={18} />
        {children}
      </Card>
    </Grid>
  )
}
const labels = ['dmarc', 'spf', 'dkim', 'mx'];
const setTable = (x, y) => {
  const formatData = {
    styles: {
      p: {
        style: {
          wordBreak: 'break-all',
          padding: 5,
          maxWidth: '75%',
          textAlign: 'justify',
        },
      },
    },
  };
  if (x[y] && x[y].length > 0) {
    const headers = x[y].map((l) => ({ key: Object.keys(l)[0], label: Object.keys(l)[0] }))
    const data = x[y].reduce(((r, c) => Object.assign(r, c)), {});
    return (
      <TableCont>
        <DmarcTable
          translate={false}
          titles={headers}
          formatData={formatData}
          details={[data] || []}
        />
      </TableCont>
    )
  }
  return <h1>no eix</h1>
}

const NoData = () => (
  <MessageBox
    message={<b><FormattedMessage id="not.have.data" /></b>}
    variant="primary"
    rest={{ color: theme.colors.grey5, bg: theme.colors.blue1 }}
  />
)
const Content = ({ data, label }) => {
  const classes = useStyles();
  if (labels.slice(0, 2)
    .includes(label)) {
    if (data.length > 0) {
      return (
        <React.Fragment>
          {data.map((it) => (
            Object.keys(it)
              .map((l) => (
                <div className={classes.list}>
                  <div className={classes.listTitle}>{l}</div>
                  <div className={classes.listContent}>{it[l]}</div>
                </div>
              ))
          ))}
        </React.Fragment>
      )
    }
    return <NoData />
  }
  if (label === 'mx') {
    if (data.length > 0) {
      const headers = Object.keys(data[0])
        .map((it) => ({
          label: it,
          key: it,
        }));
      return (
        <TableCont>
          <DmarcTable
            titles={headers}
            details={data || []}
          />
        </TableCont>
      );
    }
    return <NoData />
  }
  if (label === 'dkim') {
    if (data.length > 0) {
      const itm = Object.keys(data[0]).map((it) => (
        <div>
          <TitleSection size={15} translate={false} id={it} separator={false} />
          { setTable(data[0], it)}
          { Object.keys(data[0]) > 1 && <Separator /> }
        </div>
      ));
      return (
        <div>
          {itm}
        </div>
      );
    }

    return <NoData />
  }
  return <NoData />
}

const setScroll = (ikElement, t) => {
  if (t === 'dkim' && ikElement.length > 0) {
    return Object.keys(ikElement[0]).length > 5
  }
  if (t === 'mx' && ikElement.length > 0) {
    return ikElement.length > 8;
  }
  return ikElement.length > 15
}

const Item = ({ data }) => {
  const classes = useStyles();


  if (data.length > 0) {
    const items = data.map((ik, i) => (
      <React.Fragment>
        <Grid
          className={classes.content}
          container
          spacing={2}
          style={{ backgroundColor: i % 2 === 0 ? theme.colors.grey1 : theme.colors.grey6 }}
        >
          {labels.map((t) => (
            <Custom key={t} actual={t} scroll={setScroll(ik[t], t)}>
              <Content data={ik[t]} label={t} />
            </Custom>
          ))}
        </Grid>
        <Separator />
      </React.Fragment>
    ))
    return (
      <div className={classes.root}>
        {items}
      </div>
    );
  }
  return null
};

export default Item;
