import React from 'react';
import { Chart } from 'react-google-charts';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import { theme } from 'src/theme';

const useClass = makeStyles(({
  root: {
    justifyContent: 'center',
  },
}))

const Loader = () => (
  <React.Fragment>
    <Skeleton variant="text" height={40} width="50%" />
    <Skeleton variant="text" height={40} width="30%" />
    <Skeleton variant="text" height={40} width="50%" />
  </React.Fragment>
)
const Pie = ({ data, title }) => {
  const classes = useClass();
  return (
    <Grid container className={classes.root}>
      <Grid item md={6} sm={8} xs={12}>
        <Chart
          height="450px"
          chartType="PieChart"
          loader={<Loader />}
          data={data}
          options={{
            title,
            titleTextStyle: {
              fontSize: 20,
              color: theme.colors.blue1,
              textAlign: 'center',
            },
            legend: 'none',
            pieSliceText: 'label',
            slices: {
              4: { offset: 0.2 },
              12: { offset: 0.3 },
              14: { offset: 0.4 },
              15: { offset: 0.5 },
            },
          }}
          rootProps={{ 'data-testid': '5' }}
        />
      </Grid>
    </Grid>
  );
};

export default Pie;
