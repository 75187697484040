export const GET_REPORT = 'app/dmarc/GET_REPORT';
export const GET_REPORT_SUCCESS = 'app/dmarc/GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'app/dmarc/GET_REPORT_ERROR';
export const GET_REPORT_CLEAR = 'app/dmarc/GET_REPORT_CLEAR';
export const DATE_REPORT_FILTER = 'app/dmarc/GET_DATE_REPORT_FILTER';
export const GET_REPORT_FORENSIC = 'app/dmarc/GET_REPORT_FORENSIC';
export const GET_REPORT_SUCCESS_FORENSIC = 'app/dmarc/GET_REPORT_SUCCESS_FORENSIC';
export const GET_REPORT_ERROR_FORENSIC = 'app/dmarc/GET_REPORT_ERROR_FORENSIC';
export const GET_REPORT_CLEAR_FORENSIC = 'app/dmarc/GET_REPORT_CLEAR_FORENSIC';
export const DATE_REPORT_FILTER_FORENSIC = 'app/dmarc/GET_DATE_REPORT_FILTER_FORENSIC';
export const GET_HISTOGRAM = 'app/dmarc/GET_HISTOGRAM';
export const GET_HISTOGRAM_SUCCESS = 'app/dmarc/GET_HISTOGRAM_SUCCESS';
export const GET_HISTOGRAM_ERROR = 'app/dmarc/GET_HISTOGRAM_ERROR';
export const GET_TOOLS = 'app/dmarc/GET_TOOLS';
export const GET_TOOLS_SUCCESS = 'app/dmarc/GET_TOOLS_SUCCESS';
export const GET_TOOLS_ERROR = 'app/dmarc/GET_TOOLS_ERROR';
export const GET_REPORT_MONTH = 'app/dmarc/GET_REPORT_MONTH';
export const GET_REPORT_MONTH_ERROR = 'app/dmarc/GET_REPORT_MONTH_ERROR';
export const GET_REPORT_MONTH_SUCCESS = 'app/dmarc/GET_REPORT_MONTH_SUCCESS';
export const GET_REPORT_TAB_MONTH = 'app/dmarc/GET_REPORT_TAB_MONTH';
export const GET_COMPANY = 'app/dmarc/GET_GET_COMPANY'
export const GET_COMPANY_SUCCESS = 'app/dmarc/GET_GET_COMPANY_SUCCESS'
export const GET_COMPANY_ERROR = 'app/dmarc/GET_GET_COMPANY_ERROR'
export const SET_ACTUAL_COMPANY = 'app/dmarc/SET_ACTUAL_COMPANY';
export const GET_USER_INFO = 'app/dmarc/GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'app/dmarc/GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'app/dmarc/GET_USER_INFO_ERROR';
export const IS_LOADIND_DATA = 'app/dmarc/IS_LOADING_DATA';
export const ACTUAL_URL = 'app/dmarc/ACTUAL_URL';
export const LOOK_LIKE = 'app/dmarc/LOOK_LIKE';
export const LOOK_LIKE_SUCCESS = 'app/dmarc/LOOK_LIKE_SUCCESS';
export const LOOK_LIKE_ERROR = 'app/dmarc/LOOK_LIKE_ERROR';
export const LOOK_LIKE_BY_IP = 'app/dmarc/LOOK_LIKE_BY_IP';
export const LOOK_LIKE_SUCCESS_BY_IP = 'app/dmarc/LOOK_LIKE_SUCCESS_BY_IP';
export const LOOK_LIKE_ERROR_BY_IP = 'app/dmarc/LOOK_LIKE_ERROR_BY_IP';
export const LOOK_LIKE_TYPE = 'app/dmarc/LOOK_LIKE_TYPE';

export const GET_NOTES = 'app/dmarc/GET_NOTES';
export const GET_NOTES_SUCCESS = 'app/dmarc/GET_NOTES_SUCCESS';
export const GET_NOTES_ERROR = 'app/dmarc/GET_NOTES_ERROR';

export const CREATE_NOTES = 'app/dmarc/CREATE_NOTES';
export const CREATE_NOTES_SUCCESS = 'app/dmarc/CREATE_NOTES_SUCCESS';
export const CREATE_NOTES_ERROR = 'app/dmarc/CREATE_NOTES_ERROR';
export const ADD_ADITIONAL_NOTE = 'app/dmarc/ADD_ADITIONAL_NOTE'

export const HISTORICAL_DNS = 'app/dmarc/HISTORICAL_DNS';
export const HISTORICAL_DNS_ERROR = 'app/dmarc/HISTORICAL_DNS_ERROR';
export const HISTORICAL_DNS_SUCCESS = 'app/dmarc/HISTORICAL_DNS_SUCCESS';

export const ERROR_ONLOAD_MATRIZ = 'app/dmarc/ERROR_ONLOAD_MATRIZ';
export const ERROR_ONLOAD_MATRIZ_LOADINNG = 'app/dmarc/ERROR_ONLOAD_MATRIZ_LOADING';
