import React from 'react';
import {
  Collapse,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import WhiteIcon from 'common/components/Utilities/WhiteIcon';
import Font from 'common/components/Font';
import { FormattedMessage } from 'react-intl';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import NavigationListItem from 'common/components/Navigation/NavigationListItem';
import styled from 'styled-components';

const CollapseM = styled(Collapse)`
  .MuiCollapse-wrapper {
    padding-left: 20px;
  }
`;
const NavigationList = ({ icon, title, data }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>{WhiteIcon(icon)}</ListItemIcon>
        <ListItemText
          primary={(
            <Font variant="h4" component="span" color="secondary">
              <FormattedMessage id={title} />
            </Font>
          )}
        />
        {open ? (
          <ListItemIcon>{WhiteIcon(ExpandLess)}</ListItemIcon>
        ) : (
          <ListItemIcon>{WhiteIcon(ExpandMore)}</ListItemIcon>
        )}
      </ListItem>
      <Divider />
      <CollapseM in={open} timeout="auto" unmountOnExit>
        {data.map((it) => (
          <NavigationListItem {...it} key={it.url} />
        ))}
      </CollapseM>
    </React.Fragment>
  );
};
export default NavigationList;
