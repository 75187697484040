import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dasboard from 'views/dashboard';
import RiskMatrix from 'views/riskmatrix';
import Report360 from 'views/reports/360';
import ReportForensic from 'views/reports/forensic';
import Histogram from 'views/reports/histogram';
import Month from 'views/reports/month';
import LookALike from 'views/lookALike';
import HistoricalDns from 'views/reports/historicalDns';
import { ReportsReceived } from 'views/reports/reportsReceived';
import { NotesFeed } from 'views/notesFeed';
import { SearchFeed } from 'views/SearchFeed';
import { SpfValid, SpfInvalid } from './views/reports/spfTop';

module.exports = (
  <div>
    <div>
      <Switch>
        <Route exact path="/" component={Dasboard} />
        <Route exact path="/dashboard" component={Dasboard} />
        <Route exact path="/riskmatrix" component={RiskMatrix} />
        <Route exact path="/report-360" component={Report360} />
        <Route exact path="/report-forensic" component={ReportForensic} />
        <Route exact path="/reports-received" component={ReportsReceived} />
        <Route exact path="/histogram" component={Histogram} />
        <Route exact path="/report-monthly" component={Month} />
        <Route exact path="/look-like" component={LookALike} />
        <Route exact path="/historical-dns" component={HistoricalDns} />
        <Route exact path="/spf-valid" component={SpfValid} />
        <Route exact path="/spf-invalid" component={SpfInvalid} />
        <Route exact path="/notes" component={NotesFeed} />
        <Route exact path="/search" component={SearchFeed} />
        <Route path="*" component={Dasboard} />
      </Switch>
    </div>
  </div>
);
