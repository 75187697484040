import React from "react";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const ToggleNotesCreation = ({ handleToggleNotesCreation }) => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery("(max-width: 1400px)");
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0 auto",
    maxWidth: "60vw",
    alignItems: "center",
    justifyContent: "end",
    maxWidth: isSmallerScreen ? "90vw" : isMediumScreen ?  "80vw" : "70vw",
  };

  const buttonStyle = {
    cursor: "pointer",
    //backgroundColor: 'blue',
    height: "1.2em",
    width: "1.2em",
    color: "#3E97E8",
    transition: "background 0.3s", // Agregar transición de animación

    // Estilo para el hover
    ":hover": {
      background: "rgba(0, 0, 0, .1)",
      borderRadius: "2px", // Cambiar color en el hover
    },
  };

  return (
    <Box sx={containerStyle}>
      <NoteAddOutlinedIcon
        sx={buttonStyle}
        onClick={handleToggleNotesCreation}
      />
    </Box>
  );
};
