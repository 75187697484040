import { GET_USER_INFO, GET_USER_INFO_SUCCESS, GET_USER_INFO_ERROR } from 'rdx/newRedux/types';


const userInfo = (state = {}, { type, payload }) => {
  const result = {
    [GET_USER_INFO]: { loading: true },
    [GET_USER_INFO_SUCCESS]: { loading: false, data: payload },
    [GET_USER_INFO_ERROR]: { loading: false, error: payload },
  }
  return { ...state, ...result[type] } || { ...state };
}
export default userInfo;
