// reducer.js
const initialState = null;

const noteDataReducer = (NotesState = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTE_DATA':
      return action.payload;
    default:
      return NotesState;
  }
};

export const setNoteData = (noteData) => ({
  type: 'SET_NOTE_DATA',
  payload: noteData,
});

export default noteDataReducer;
