import React from 'react';
import { asyncActions, selectDisplay } from 'rdx/summary/actions';
import { currentDomainSelector, domainDetailsSelector } from 'rdx/summary/selectors';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import Layout from 'common/components/Layout';
import Grid from '@material-ui/core/Grid';
import TitleSection from 'common/components/Message/TitleSection';
import Domains from 'views/riskmatrix/Domains';
import Container from 'common/components/FlexContainer/Container';
import dataApi from 'rdx/newRedux/api/dataApi';
import Action from 'rdx/newRedux/actions/Action';
import { LOOK_LIKE, LOOK_LIKE_BY_IP } from 'rdx/newRedux/types';
import _ from 'lodash';
import { Scroll } from 'common/utils/services/scroll.service';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import {
  KEY_LOOK_A_LIKE,
  KEY_LOOK_A_LIKE_LOADING,
  KEY_LOOK_A_LIKE_SELECTED,
} from 'rdx/newRedux/selectores/keys';
import RadioButtonList from 'common/components/RadioButtonList/RadioButtonList';
import { FormattedMessage } from 'react-intl';
import { theme } from 'src/theme';
import MessageBox from 'common/components/Message/MessageBox';
import Content from 'views/lookALike/content';
import SkeletoLoading from 'common/components/Skeleton/SkeletoLoading';
import SkeletonRadioButton from 'common/components/Skeleton/SkeletonRadioButton';
import { ContainerCardRadio } from 'common/components/FlexContainer/CardContainer';

const View = ({
  customerId, loadPrecalculated, domainDetails, intl, domain, email
}) => {
  const reports = GetSelector(KEY_LOOK_A_LIKE);
  const dispatch = useDispatch();
  const [type, setType] = React.useState();
  const [actualType, setActualType] = React.useState();
  const loading = GetSelector(KEY_LOOK_A_LIKE_LOADING);
  const selected = GetSelector(KEY_LOOK_A_LIKE_SELECTED);
  React.useEffect(() => {
    if (!type && reports) {
      const keys = _.keys(reports);
      // eslint-disable-next-line no-unused-expressions
      keys.length > 0 ? keys.shift() : null
      setType(keys);
    }
  }, [reports])
  const setDispatch = () => {
    const payload = {
      params: {
        id: customerId,
        dominio: domain,
      },
      ...dataApi.lookALike,
    }
    const payloadByIp = {
      data: {
        id: customerId,
        domain,
      },
      ...dataApi.lookALikeByIp,
    }
    dispatch(Action(LOOK_LIKE, payload));
    dispatch(Action(LOOK_LIKE_BY_IP, payloadByIp));
  }
  React.useEffect(() => {
    if (customerId && domain) {
      setType(null);
      setActualType(null);
      setDispatch();
    }
  }, [domain, customerId]);
  React.useEffect(() => {
    if (customerId && _.isEmpty(domainDetails)) loadPrecalculated({ customerId, email });
    Scroll.scrollTop()
  }, [customerId]);
  const typesReport = type && type.length > 0 && type.map((item) => ({ value: item, label: item }));

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <TitleSection id="lookALike" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Domains type="look-like" />
            </Grid>
            { selected === 0 && (
            <Grid item xs={12}>
              {typesReport
              && (
                <RadioButtonList
                  title="select.type"
                  details={typesReport}
                  onSelected={(e) => setActualType(e.target.value)}
                  selected={actualType}
                  loading
                />
              )
              }
              {
                loading
                && (
                <ContainerCardRadio>
                  <SkeletonRadioButton length={5} />
                </ContainerCardRadio>
                )
              }
            </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Container marginTop={64}>
            {
              loading && <SkeletoLoading />
            }
            { !loading && type && type.length === 0 && (
            <MessageBox
              message={<b><FormattedMessage id="not.have.data" /></b>}
              variant="primary"
              rest={{ color: theme.colors.grey5, bg: theme.colors.blue1 }}
            />
            )}
            {!loading && type && type.length > 0
              && <Content intl={intl} reports={reports} actual={actualType} />
            }

          </Container>
        </Grid>
      </Grid>

    </Layout>
  );
};

const mapDispatchToProps = {
  loadPrecalculated: asyncActions.loadAction,
  setDisplay: selectDisplay,

}
const mapStateToProps = (state) => ({
  domain: currentDomainSelector(state),
  domainDetails: domainDetailsSelector(state),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(
  withRouter(withUserInfo(View))
);
