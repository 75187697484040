import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { FetchNote } from 'servicio/FetchNote';

export const NotesIcon = ({ data, actualId }) => {
  const { id } = actualId;
  const token = data.jwtToken;
  const { respuesta } = FetchNote({ id, token });

  const [shouldReload, setShouldReload] = useState(false);

  const buttonStyle = {
    width: '1.3em',
    height: '1.3em',
    margin: '0 15px',

    transition: 'background 0.3s',

    ':hover': {
      background: 'rgba(0, 0, 0, .1)',
      borderRadius: '2px',
    },
  };

  if (respuesta === null) return (<h1 />);

  const handleLinkClick = () => {
    setShouldReload(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Link to="/notes" onClick={handleLinkClick}>
        {
          respuesta.values.length > 0 ? (
            <NotificationImportantIcon
              sx={{ color: 'red', ...buttonStyle }}
            />
          )
            : (
              <NotificationsIcon
                sx={{ color: '#3E97E8', ...buttonStyle }}
              />
            )
        }
      </Link>
    </div>
  );
};
