import axios from 'axios';

const DMARC_API = __CONFIG__.api;
const DMARC_API_PRO = 'https://w38v4fse3k.execute-api.us-east-1.amazonaws.com/Prod';
const DMARC_API_PROD = 'https://rixp7qllya.execute-api.us-east-1.amazonaws.com/PROD';
const DMARC_API_ADMIN = 'https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/Prod';
const DMARC_API_V1 = 'https://w38v4fse3k.execute-api.us-east-1.amazonaws.com/v1'


const setUrl = (url) => {
  if (url.includes('Prod')) return DMARC_API_PRO + url.split('/Prod').join('')
  if (url.includes('PROD')) return DMARC_API_PROD + url.split('/PROD').join('')
  if (url.includes('ADMIN')) return DMARC_API_ADMIN + url.split('ADMIN').join('');
  if (url.includes('v1')) return DMARC_API_V1 + url.split('v1').join('');
  return `${DMARC_API}${url}`
}

const apiCall = (url, method, params = null, data = null, headers = null) => axios({
  method,
  params,
  url: setUrl(url),
  data,
  headers: {
    'Content-Type': 'application/json', Accept: 'application/json', ...headers,
  },
});


export default apiCall;
