import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import Layout from 'common/components/Layout';
import { Grid } from '@material-ui/core';
import RadioButtonList from 'common/components/RadioButtonList/RadioButtonList';
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import Container from 'common/components/FlexContainer/Container';
import Datepicker from 'common/components/Date/Datepicker';
import TitleSection from 'common/components/Message/TitleSection';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_REPORT_MONTH_DETAIL, KEY_REPORT_MONTH_LOADING } from 'rdx/newRedux/selectores/keys';
import dataApi from 'rdx/newRedux/api/dataApi';
import { parserMonth } from 'rdx/newRedux/parser/reports';
import Action from 'rdx/newRedux/actions/Action';
import { GET_REPORT_MONTH, GET_REPORT_MONTH_SUCCESS } from 'rdx/newRedux/types';
import Type from 'views/reports/month/type/Type';
import Domains from 'views/riskmatrix/Domains';
import isEmpty from 'lodash/isEmpty';
import { Scroll } from 'common/utils/services/scroll.service';
import { asyncActions, selectDisplay } from 'rdx/summary/actions';
import { currentDomainSelector, domainDetailsSelector } from 'rdx/summary/selectors';
import SkeletoLoading from 'common/components/Skeleton/SkeletoLoading';
import AsyncAction from 'rdx/newRedux/actions/AsyncAction';

const View = ({
  customerId, intl, domainDetails, domain, loadPrecalculated, email,
}) => {
  const [type, setType] = React.useState(null);
  const [date, setDate] = React.useState();
  const onChangeDate = (e) => {
    setDate(format(new Date(e), 'MM-yyyy'));
  }
  const data = GetSelector(KEY_REPORT_MONTH_DETAIL);
  const loading = GetSelector(KEY_REPORT_MONTH_LOADING);
  const dispatch = useDispatch();
  const onCall = () => {
    const actData = dataApi.reportMonth;
    const [month, year] = date.split('-');
    const payload = {
      params: {
        id: customerId,
        domain,
        year,
        month,
        type,
      },
      ...actData,
      parser: (d) => parserMonth(data, `type${type}`, d),
    }
    dispatch(Action(GET_REPORT_MONTH, payload));
  }
  React.useEffect(() => {
    if (date && type) {
      let key = false;
      try {
        key = Object.keys(data).includes(`type${type}`);
      } catch (w) {
        // error
      }
      if (!key && customerId && domain) onCall();
    }
  }, [type]);

  React.useEffect(() => {
    dispatch(AsyncAction(GET_REPORT_MONTH_SUCCESS));
    setType(null);
  }, [domain, date]);

  React.useEffect(() => {
    if (customerId && isEmpty(domainDetails)) loadPrecalculated({ customerId, email });
    Scroll.scrollTop()
  }, [customerId]);
  const headerTypes = Array.from(Array(5).keys()).map((i) => ({ value: `${i + 1}`, label: `type${i + 1}` }));
  const visibleType = date && type && data && data[`type${type}`];
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <TitleSection id="reports.month" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Datepicker
                visible
                views={['year', 'month']}
                onSelected={onChangeDate}
                reset
                loading={!domain || loading}
                title={false}
              />
            </Grid>
            {date
            && (
            <Grid item xs={12}>
              <RadioButtonList
                title="select.type"
                details={headerTypes}
                onSelected={(e) => setType(e.target.value)}
                selected={type}
                loading={!domain || !loading}
              />
            </Grid>
            )
            }
          </Grid>
          <Grid item xs={12}>
            <Domains type="report-monthly" loading={loading} />
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Container marginTop={64}>
            { visibleType && <Type data={data} type={`type${type}`} intl={intl} /> }
            {!date && (
            <TitleSection id="select.year.month" size={30} />
            )}
            {date && !type && (
            <TitleSection id="select.type" size={30} />
            )}
            { loading && <SkeletoLoading />}
          </Container>
        </Grid>
      </Grid>
    </Layout>
  );
};

const mapDispatchToProps = {
  loadPrecalculated: asyncActions.loadAction,
  setDisplay: selectDisplay,

}
const mapStateToProps = (state) => ({
  domain: currentDomainSelector(state),
  domainDetails: domainDetailsSelector(state),
})
export default connect(
  mapStateToProps, mapDispatchToProps
)(
  withRouter(withUserInfo(View))
);
