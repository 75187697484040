// import React from 'react'
// import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';
// import { FormattedMessage, injectIntl } from 'react-intl';
// const divStyle = {
//   width: '100px',
//   height: '60px',
//   textAlign: 'center',
//   fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   display: 'flex',
//   padding: '10px',
//   transition: 'transform 0.2s',
//   cursor: 'pointer',
//   margin: '0 10px',
//   borderRadius: '5px',
// };

// export const GraphsSearchFeed = ({ valuesDkimToRender, handleOptionClick }) => {
//   const titles = ['Pass - Pass', 'Pass - Fail', 'Fail - Pass', 'Fail - Fail'];
//   const colors = ['#7DCE13', '#FF9900', '#FF9900', '#C21010'];
//   const formattedValues = valuesDkimToRender.map((value) => {
//     // Supongo que 'value' es un número, si no, ajústalo según tu caso.
//     return FormatNumberESService.formatNumber(intl, value);
//   });

//   return (
//     <div style={{ display: 'flex', justifyContent: 'center' }}>
//       {formattedValues.map((value, index) => {
//         const title = titles[index];
//         const backgroundColor = colors[index];
//         return (
//           <div
//             key={index}
//             style={{
//               ...divStyle,
//               backgroundColor,
//             }}
//             onMouseEnter={(e) => {
//               e.currentTarget.style.transform = 'scale(1.05)';
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.transform = 'scale(1)';
//             }}
//             onClick={() => handleOptionClick({ index })}
//           >
//             <h2 style={{ fontSize: '16px', marginBottom: '0' }}>{title}</h2>
//             <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{value}</p>
//           </div>
//         );
//       })}
//     </div>
//   )
// }
import React, { Component } from 'react';
import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';
import { FormattedMessage, injectIntl } from 'react-intl';

const divStyle = {
  minWidth: '100px',
  height: '60px',
  textAlign: 'center',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  padding: '10px',
  transition: 'transform 0.2s',
  cursor: 'pointer',
  margin: '0 10px',
  borderRadius: '5px',
};

class GraphsSearchFeed extends Component {
  render() {
    const { valuesDkimToRender, handleOptionClick, intl } = this.props;
    const titles = ['PassPass', 'PassFail', 'FailPass', 'FailFail'];
    const colors = ['#7DCE13', '#FF9900', '#FF9900', '#C21010'];

    const formattedValues = valuesDkimToRender.map((value) => {
      return FormatNumberESService.formatNumber(intl, value);
    });

    return (
      <div style={{
        display: 'flex', justifyContent: 'center', padding: '15px 0', borderRadius: '4px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 5px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px',
      }}
      >
        {/* <h3 style={{ color: '#3E97EB' }}>DKIM</h3> */}
        {/* <div> */}
        {formattedValues.map((value, index) => {
          const title = titles[index];
          const backgroundColor = colors[index];
          return (
            <div
              key={index}
              style={{ margin: '0 10px' }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
              onClick={() => handleOptionClick({ index })}
            >
              <h4 style={{ color: '#3E97EB', marginBottom: '5px', fontSize: '15px' }}><FormattedMessage id={title} /></h4>
              <div
                key={index}
                style={{
                  ...divStyle,
                  backgroundColor,
                }}
                // onMouseEnter={(e) => {
                //   e.currentTarget.style.transform = 'scale(1.05)';
                // }}
                // onMouseLeave={(e) => {
                //   e.currentTarget.style.transform = 'scale(1)';
                // }}
                // onClick={() => handleOptionClick({ index })}
              >
                {/* <h2 style={{ fontSize: '16px', marginBottom: '0' }}><FormattedMessage id={title} /></h2> */}
                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{value}</p>
              </div>
            </div>
          );
        })}
        {/* </div> */}
      </div>
    );
  }
}

export default injectIntl(GraphsSearchFeed);
