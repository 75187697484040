export const RADIO_ITEMS_REPORT_360 = [
  {
    value: '0',
    label: 'reports.radio.by.ip.country',
  },
  {
    value: '1',
    label: 'table.country',
  },
]
export const RADIO_ITEMS_REPORT_FORENSIC = [
  {
    value: 'subject',
    label: 'reports.radio.by.subject',
  },
  {
    value: 'ip',
    label: 'risk.matrix.table.ip',
  },
  {
    value: 'pais',
    label: 'table.country',
  },
];

export const RADIO_SELECTED_RANGE = [
  {
    value: '7',
    label: '7',
  },
  {
    value: '15',
    label: '15',
  },
  {
    value: '30',
    label: '30',
  },
]
export const RADIO_SELECTED_MONTH = [
  {
    value: 'type1',
    label: 'type1',
  },
  {
    value: 'type2',
    label: 'type2',
  },
  {
    value: 'type3',
    label: 'type3',
  },
  {
    value: 'type4',
    label: 'type4',
  },
  {
    value: 'type4',
    label: 'type4',
  }, {
    value: 'type4',
    label: 'type4',
  },
]
