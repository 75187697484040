import React from 'react';
import styled from 'styled-components';

import Font from 'common/components/Font';
import { theme } from 'src/theme';
import { FormattedMessage } from 'react-intl';
import Separator from 'common/components/Separator';

const Title = styled(Font)`
  color: ${(props) => (props.color ? theme.colors[props.color] : theme.colors.blue1)};
  font-size: ${(props) => (props.size ? `${props.size}px !important` : '40px !important')};
`;
const TitleSection = ({
  id, size, className = '', separator = true, marginSeparator, translate = true,
}) => {
  return (
    <Title variant="h1" component="span" size={size} className={className}>
      { translate && <b><FormattedMessage id={id} /></b> }
      { !translate && <b>{id}</b>}
      { separator && <Separator margin={marginSeparator} /> }
    </Title>
  );
};

export default TitleSection;
