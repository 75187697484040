import React from 'react';
import {
  Assessment,
  AssignmentLate,
  Business,
  Dns,
  EventNote,
  Timeline,
} from '@material-ui/icons';
import NavigationList from 'common/components/Navigation/NavigationList';
import ForumIcon from '@mui/icons-material/Forum';

const data = [
  { url: '/report-360', label: 'menu.reports.360', icon: Assessment },
  {
    url: '/report-forensic',
    label: 'menu.reports.forensic',
    icon: AssignmentLate,
  },
  { url: '/reports-received', label: 'menu.reports.received', icon: ForumIcon },
  { url: '/histogram', label: 'menu.reports.histogram', icon: Timeline },
  { url: '/report-monthly', label: 'reports.month', icon: EventNote },
  { url: '/historical-dns', label: 'reports.historicaldns', icon: Dns },
];
const icon = Business;
const title = 'menu.reports';
const ReportList = () => (
  <NavigationList icon={icon} data={data} title={title} />
);

export default ReportList;
//         <NavigationListItem url="/report-monthly" label="reports.month" icon={EventNote} />
