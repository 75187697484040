import React from 'react';
import { TableCont } from 'common/components/FlexContainer/CardContainer';
import DmarcTable from 'common/components/Table/DmarcTable';
import MessageBox from 'common/components/Message/MessageBox';
import { FormattedMessage, injectIntl } from 'react-intl';
import { theme } from 'src/theme';
import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';
import countries from 'i18n-iso-countries';
import Simple from 'views/reports/month/type/download/Simple';
import useClass from 'views/reports/month/type/style';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

const titles = {
  pais: 'table.country',
  dominio: 'domain',
  contador: 'risk.matrix.table.messages',
  ip: 'risk.matrix.table.ip',
  sender: 'risk.matrix.table.senders',
  asunto: 'reports.radio.by.subject',
};

const TableDetail = ({
  data, tabIndex, intl, title, keyReport,
}) => {
  const _keys = data[tabIndex].values[0] && Object.keys(data[tabIndex].values[0])
  const headerTable = _keys.map((key) => ({ key, label: titles[key] }));
  const language = intl.locale === 'es' ? 'es' : 'en';
  const [orderByKey, setOrderByKey] = React.useState('contador');
  const [asc, setAsc] = React.useState('desc');
  const classes = useClass();
  const formatData = {
    format: {
      contador: (d) => FormatNumberESService.formatNumber(intl, d),
      pais: (code) => countries.getName(code, language) || code.toLowerCase(),
    },
  };
  const onOrderBy = (key) => {
    if (key !== orderByKey) {
      setOrderByKey(key);
    }
    if (asc === 'asc') {
      setAsc('desc');
    } else {
      setAsc('asc');
    }
  }
  const [down, setDown] = React.useState([]);
  const onOpen = (event, done) => {
    const response = [];
    response.push(headerTable.map((a) => intl.formatMessage({ id: a.label })));
    data[tabIndex].values.forEach((item) => {
      const ac = _keys.map((a) => {
        if (a === 'pais') return countries.getName(item[a], language) || item[a].toUpperCase()
        return item[a]
      })
      response.push(ac)
    });
    setDown(response)
    done(true)
  }
  const name = `${intl.formatMessage({ id: title })}_${intl.formatMessage({ id: keyReport })}`
  return (
    <React.Fragment>
      {data.length > 0 ? (
        <TableCont top="20px">
          <div className={classes.buttonDownloadTypeN}>
            <Simple
              data={down}
              onOpen={((event, done) => onOpen(event, done))}
              name={name}
            />
          </div>
          <DmarcTable
            details={data[tabIndex].values || []}
            titles={headerTable}
            classname="dark"
            formatData={formatData}
            onClick={onOrderBy}
            asc={asc}
          />
        </TableCont>
      ) : (
        <MessageBox
          top="20px"
          message={<b><FormattedMessage id="not.have.data" /></b>}
          variant="primary"
          rest={{ color: theme.colors.grey5, bg: theme.colors.blue1 }}

        />
      ) }
    </React.Fragment>
  );
};

export default injectIntl(TableDetail);
