import {
  GET_NOTES,
  GET_NOTES_SUCCESS,
  GET_NOTES_ERROR,
  CREATE_NOTES,
  CREATE_NOTES_SUCCESS,
  CREATE_NOTES_ERROR, ADD_ADITIONAL_NOTE,
} from 'rdx/newRedux/types';


export const Notes = (state = { dataAditional: [] }, { type, payload }) => {
  const result = {
    [GET_NOTES]: { loading: true, data: null, dataAditional: [] },
    [GET_NOTES_SUCCESS]: { loading: false, data: payload },
    [GET_NOTES_ERROR]: { loading: false, error: payload },
    [CREATE_NOTES]: { loadingCreate: true, dataCreate: undefined, errorCreate: undefined },
    [CREATE_NOTES_SUCCESS]: { loadingCreate: false, dataCreate: payload, errorCreate: undefined },
    [CREATE_NOTES_ERROR]: { loadingCreate: false, errorCreate: payload, dataCreate: undefined },
    [ADD_ADITIONAL_NOTE]: { dataAditional: [...state.dataAditional, payload] },
  }
  return { ...state, ...result[type] } || { ...state };
}
