import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { KEY_ACTUAL_COMPANY } from 'rdx/newRedux/selectores/keys';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { UpdateNote } from 'servicio/FetchUpdateNote'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5),
    minWidth: '430px',
    minHeight: '40vh',
    border: 'none',
    position: 'relative',
    borderRadius: '5px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sendButton: {
    margin: '20px auto',
    width: '60%',
    '&:hover': {
      color: '#3E97E8',
    },
  },
  customButton: {
    background: '#3E97E8',
    width: '100%',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      color: '#3E97E8',
    },
  },
  formGroup: {
    justifyContent: 'center',
    marginTop: '15px',
    marginBottom: '15px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute !important',
    top: '10px',
    right: '10px',
  },
}));

export const EditNoteModal = ({
  openModal, onClose, data, setIsSubmitted,
}) => {
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const classes = useStyles();
  const [nota, setNota] = useState('');
  const [responsable, setResponsable] = useState('');
  const [urgente, setUrgente] = useState(false);
  const [tipoNota, setTipoNota] = useState('seguimiento');
  const [fechaCompromiso, setFechaCompromiso] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formValid, setFormValid] = useState(true);
  const token = noteData.jwtToken;
  const actualName = GetSelector(KEY_ACTUAL_COMPANY);
  const url = 'https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/v1/notes/update-notes'
  const method = 'POST'
  const handleChangeResponsable = (event) => {
    setResponsable(event.target.value);
  };

  const handleCheckUrgente = (event) => {
    setUrgente(event.target.checked);
    if (event.target.checked) {
      setTipoNota('seguimiento');
    } else {
      setTipoNota('informativa');
    }
  };

  const handleChangeTipoNota = (event) => {
    setTipoNota(event.target.value);
    if (event.target.value === 'informativa') {
      setUrgente(false)
      setFechaCompromiso('')
    }
  };
  useEffect(() => {
    if (openModal) {
      setNota(data.description || '');
      setResponsable(data.responsable === 'C' ? 'cliente' : 'DmarcGuardian' || '');
      setUrgente(data.note_type === 'U');
      setTipoNota(data.note_subtype === 'S' ? 'seguimiento' : 'informativa');
      setFechaCompromiso(data.date_followup || '');
    }
  }, [openModal, data]);
  const handleSend = async () => {
    if (!nota || !responsable || (!urgente && !tipoNota) || (tipoNota === 'seguimiento' && !fechaCompromiso)) {
      setFormValid(false);
      return;
    }
    try {
      setLoading(true);
      setErrorMessage(null);
      const requestData = {
        id: data.id,
        table: 'P',
        description: nota,
        responsable,
        note_type: urgente ? 'U' : 'N',
        note_subtype: tipoNota === 'seguimiento' ? 'S' : 'I',
        status: tipoNota === 'seguimiento' ? 'P' : 'C',
        date_followup: fechaCompromiso,
      };
      await UpdateNote({
        requestData, token, method, url,
      });
      setLoading(false);
      setIsSubmitted(true);
      // onClose();
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
      // onClose();
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      className={classes.modal}
    >
      <div className={classes.paper}>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <h2 style={{ color: '#3E97E8', textAlign: 'center' }}><FormattedMessage id="Edit.note" /></h2>
        <TextField
          id="filled-multiline-static"
          required
          label={<FormattedMessage id="note" />}
          multiline
          minRows={4}
          variant="filled"
          fullWidth
          value={nota}
          onChange={(e) => setNota(e.target.value)}
          error={!formValid && !nota}
          helperText={!formValid && !nota ? <FormattedMessage id="FieldsRequired" /> : ''}
        />
        <FormGroup row className={classes.formGroup}>
          {tipoNota !== 'informativa'
          && (
          <FormControlLabel
            control={(
              <Checkbox
                required
                checked={urgente}
                onChange={handleCheckUrgente}
                color="primary"
              />
            )}
            label={<FormattedMessage id="Urgent" />}
          />
          )
            }
          <FormControl className={classes.formControl}>
            <InputLabel><FormattedMessage id="Responsible" /></InputLabel>
            <Select
              value={responsable}
              onChange={handleChangeResponsable}
              required
              error={!formValid && !responsable}
            >
              <MenuItem value="cliente">{actualName.name}</MenuItem>
              <MenuItem value="DmarcGuardian">DmarcGuardian</MenuItem>
            </Select>
            {!formValid && !responsable && <div style={{ color: 'red' }}><FormattedMessage id="FieldsRequired" /></div>}
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel><FormattedMessage id="TypeOfNote" /></InputLabel>
            <Select
              value={tipoNota}
              required
              onChange={handleChangeTipoNota}
              error={!formValid && !tipoNota}
            >
              <MenuItem value="seguimiento"><FormattedMessage id="FollowUp" /></MenuItem>
              {data.followup_notes[0].sequence === null && <MenuItem value="informativa"><FormattedMessage id="Informative" /></MenuItem>}
            </Select>
            {!formValid && !tipoNota && <div style={{ color: 'red' }}><FormattedMessage id="FieldsRequired" /></div>}
          </FormControl>
        </FormGroup>
        {tipoNota === 'seguimiento' && (
        <TextField
          label={<FormattedMessage id="DueDate" />}
          type="date"
          variant="outlined"
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={fechaCompromiso}
          onChange={(e) => setFechaCompromiso(e.target.value)}
          error={!formValid && !fechaCompromiso}
          helperText={!formValid && !fechaCompromiso ? <FormattedMessage id="FieldsRequired" /> : ''}
        />
        ) }
        <Box className={classes.sendButton}>
          <Button variant="contained" onClick={handleSend} className={classes.customButton}>
            {loading ? <CircularProgress style={{ color: 'white' }} size={22} /> : <FormattedMessage id="Send" />}
          </Button>
        </Box>
        {errorMessage && !loading && (
          <div style={{ color: 'red', textAlign: 'center' }}><FormattedMessage id="AnErrorHasOcurred" />, {errorMessage}</div>
        )}
      </div>
    </Modal>
  );
};
