import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Font from 'common/components/Font';

export const AccordionNote = ({ index, value }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      key={index}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}bh-content`}
        id={`panel${index}bh-header`}
        sx={{
          backgroundColor: '#3E97E8', borderRadius: '3px', // Agrega el fondo azul aquí
        }}
      >
        {/* <Font
          variant="h4"
          component="h2"
          style={{
            background: '#3E97E8', padding: 6, fontWeight: 'bold',
          }}
        >
          {value.title}
        </Font> */}
        <Typography sx={{
          width: '33%', flexShrink: 0, fontWeight: '600', color: 'white',
        }}
        >
          {value.title}
        </Typography>
        <Typography sx={{
          width: '25%', flexShrink: 0, color: 'rgba(255, 255,255, 0.8);', fontWeight: '600', textAlign: 'center',
        }}
        >
          {value.date_created}
        </Typography>
        <Typography sx={{
          width: '33%', flexShrink: 0, color: 'rgba(255, 255,255, 0.8);', fontWeight: '600', textAlign: 'center', wordWrap: 'break-word',
        }}
        >
          {value.user_created}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ color: 'black' }}>{value.note}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}
