export const FetchReport = async (noteData, domain, actualId, type) => {
  const url = 'https://w38v4fse3k.execute-api.us-east-1.amazonaws.com/v1/files/aggregated';
  const tok = noteData.jwtToken;
  const { id } = actualId;
  const body = {
    domain,
    id,
    type,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tok}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return { loading: false, data }; // Retorna el estado de carga y los datos de la respuesta
  } catch (error) {
    return { loading: false, error }; // Retorna el estado de carga y el objeto de error
  }
};
