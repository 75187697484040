import React from 'react';
import Type1 from 'views/reports/month/type/type1/Type1';
import TypeN from 'views/reports/month/type/typeN/TypeN';
import { Grid, makeStyles } from '@material-ui/core';
import { theme } from 'src/theme';
import { FormattedMessage, injectIntl } from 'react-intl';
import Font from 'common/components/Font';
import TitleSection from 'common/components/Message/TitleSection';
import { TITLES_TYPE1 } from 'common/constants/monthReport';
import Simple from 'views/reports/month/type/download/Simple';

const useClass = makeStyles({
  title: {
    fontSize: 18,
    color: theme.colors.blue4,
    marginBottom: 10,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})
const Type = ({ type, intl, data }) => {
  const classes = useClass();
  const visible = (type === 'type1' && Object.keys(data.type1).length > 0) || data[type].length > 0;
  const [download, setDownload] = React.useState([]);
  const getData = new Promise((resolve, reject) => {
    const c = [];
    const t = [];
    const response = [];
    Object.keys(TITLES_TYPE1).forEach((id) => {
      t.push(intl.formatMessage({ id: TITLES_TYPE1[id] }))
      c.push(data[type][id])
    });
    response.push(c);
    response.unshift(t);
    if (response.length === 0) {
      reject(new Error('is empty'))
    }
    resolve(response)
  });
  const onOpen = async (event, done) => {
    try {
      const send = await getData;
      setDownload(send)
      done(true);
    } catch (e) {
      console.error(e)
      done(false)
    }
  }

  if (visible) {
    return (
      <Grid direction="column" container spacing={1}>
        <div className={classes.content}>
          <Font variant="h1" component="span" className={classes.title}>
            <b><FormattedMessage id={type} /></b>
          </Font>
          {type === 'type1' && (
          <Simple
            data={download}
            name={intl.formatMessage({ id: 'type1' })}
            onOpen={((event, done) => onOpen(event, done))}
          />
          )}
        </div>
        {type === 'type1' ? <Type1 data={data[type]} intl={intl} /> : <TypeN keyReport={type} intl={intl} data={data[type]} />}
      </Grid>
    );
  }
  return (
    <Grid direction="column" container spacing={1}>
      <TitleSection id="not.have.data" size={30} />
    </Grid>
  );
}

export default injectIntl(Type);
