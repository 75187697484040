import React, { useState } from 'react';
import {
  TableCell, Button, Popover, Typography, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';

export const C46TableCell = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const DataParse = JSON.parse(data.replaceAll("'", '"'));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <TableCell style={{ display: 'flex' }}>
      <Button
        onClick={handleClick}
        style={{
          backgroundColor: '#3E97EB', color: 'white', fontSize: '11px', height: '28px',
        }}
      >
        + Info
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <div style={{ padding: '30px 16px 16px', backgroundColor: '#2D323E', position: 'relative' }}>
          <IconButton
            aria-label="close"
            size="small"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: 'white',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {DataParse.map((item, index) => (
            <div key={index} style={{ color: 'white' }}>
              <Typography variant="subtitle1" style={{ color: '#3E97EB', fontWeight: 'bold', fontSize: '16px' }}>
                <FormattedMessage id="AuthorizationResultDkim" /> {index + 1}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}><FormattedMessage id="Domain" /> </span>{item.domain}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}><FormattedMessage id="Selector" /> </span> {item.selector}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}><FormattedMessage id="Result" /> </span>{item.result}
              </Typography>
            </div>
          ))}
        </div>
      </Popover>
    </TableCell>
  );
};
