import React from 'react'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorNote } from 'common/components/SuccessNote';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  textField: {
    '& label.Mui-focused': {
      color: '#3E97E8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3E97E8',
    },
  },
}));

export const SearchContainerView = ({
  handleSubmit, handleInputChange, errorMessage, formValid, isLoading, data, inputValues,
}) => {
  const domain = window.location.href.split('=').pop().trim();
  const classes = useStyles();
  return (
    <Grid item md={8} xs={8} style={{ margin: '0 auto' }}>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          padding: '5px 20px',
          borderRadius: '4px',
          boxShadow: '0 4px 10px -2px rgba(0,0,0,0.06), 0px 4px 10px -2px rgba(0,0,0,0.06), 0px 4px 10px -2px rgba(0,0,0,0.06)',
        }}
        >
          <TextField
            label="Insert IP"
            id="standard-ip"
            size="small"
            onChange={(event) => handleInputChange(event, 'ip')}
            value={inputValues.ip || ''}
            className={classes.textField}
            required
            error={!formValid}
            helperText={!formValid ? <FormattedMessage id="FieldsRequired" /> : ''}
          />
          <TextField
            label="Sender (optional)"
            id="standard-sender"
            size="small"
            onChange={(event) => handleInputChange(event, 'sender')}
            value={inputValues.sender || ''}
            className={classes.textField}
          />
          <p style={{ margin: '0 10px', color: 'rgba(0, 0, 0, 0.6)' }}>{`(${domain})`}</p>
          <Button
            variant="contained"
            type="submit"
            size="small"
            style={{ backgroundColor: '#3E97E8', color: 'white' }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress style={{ color: 'white' }} size={22} /> : <FormattedMessage id="Search" />}
          </Button>
          { errorMessage && <ErrorNote error={errorMessage} /> }
        </div>
      </form>

    </Grid>
  )
}
