import { takeLatest } from 'redux-saga/effects';
import { CREATE_NOTES, GET_NOTES } from 'rdx/newRedux/types';
import { callToApi } from 'rdx/newRedux/sagas';

export function* getNotes() {
  yield takeLatest(GET_NOTES, callToApi)
}

export function* createNote() {
  yield takeLatest(CREATE_NOTES, callToApi);
}
