import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(({
  titleDatePicker: {
    marginBottom: 15,
  },
  contentDatepicker: {
    alignItems: 'center',
    display: 'flex',
  },
  datepicker: {
    width: 150,
  },
}));

export default useStyles;
