import { takeLatest } from 'redux-saga/effects';
import { LOOK_LIKE, LOOK_LIKE_BY_IP } from 'rdx/newRedux/types';
import { callToApi } from 'rdx/newRedux/sagas';

export function* getLookALike() {
  yield takeLatest(LOOK_LIKE, callToApi)
}
export function* getLookALikeByIp() {
  yield takeLatest(LOOK_LIKE_BY_IP, callToApi)
}
