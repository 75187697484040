import styled from 'styled-components';

import Card from '@material-ui/core/Card';

const StyledCard = styled(Card)`
  height: ${(props) => (props.height ? props.height : 'inherit')};
  width: ${(props) => (props.width ? props.width : 'inherit')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  margin-top: ${(props) => (props.top ? props.top : '0')};;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '0')};
  &.MuiPaper-root {
    box-shadow: 0 4px 10px -2px rgba(0,0,0,0.06), 0px 4px 10px -2px rgba(0,0,0,0.06), 0px 4px 10px -2px rgba(0,0,0,0.06);
      background: ${(props) => (props.bg ? props.bg : 'white')};
  }
`;

export default StyledCard;
