export const LABEL_REPORT_TABS = [
  { label: 'reports.tabs.labels.pass.pass', id: 1 },
  { label: 'reports.tabs.labels.pass.neutral', id: 2 },
  { label: 'reports.tabs.labels.pass.fail', id: 3 },
  { label: 'reports.tabs.labels.neutral.pass', id: 4 },
  { label: 'reports.tabs.labels.neutral.neutral', id: 5 },
  { label: 'reports.tabs.labels.neutral.fail', id: 6 },
  { label: 'reports.tabs.labels.fail.pass', id: 7 },
  { label: 'reports.tabs.labels.fail.neutral', id: 8 },
  { label: 'reports.tabs.labels.fail.fail', id: 9 },
];

export const LABEL_REPORT_TABLE = [
  {
    label: 'table.country',
    key: 'pais',
  },
  {
    label: 'risk.matrix.table.ip',
    key: 'ip',
  },

  /*  {
    label: 'table.reversedns',
    key: 'reverseDNS',
  }, */
  {
    label: 'dashboard.summary.messagesTotal',
    key: 'cont',
  },
];

export const LABEL_REPORT_TABLE_FORENSIC = {
  ip: [
    {
      label: 'risk.matrix.table.ip',
      key: 'ip',
    },
    {
      label: 'dashboard.summary.messagesTotal',
      key: 'count',
    },
  ],
  subject: [
    {
      value: 'subject',
      label: 'reports.radio.by.subject',
    },
    {
      label: 'dashboard.summary.messagesTotal',
      key: 'count',
    },

  ],
  pais: [
    {
      value: 'pais',
      label: 'table.country',
    },
    {
      label: 'dashboard.summary.messagesTotal',
      key: 'count',
    },
  ],
}

export const TABLE_NOTES = [
  {
    label: 'priority',
    key: 'type',
  },
  {
    label: 'date.created',
    key: 'date',
  },
  {
    label: 'note',
    key: 'note',
  },
]
export const TITLES_LOOK_LIKE_TAB = [
  { label: 'by.type', id: 0 },
  { label: 'risk.matrix.table.byip', id: 1 },
]
export const TABLE_LOOK_A_LIKE_BY_IP = [
  {
    label: 'reports.radio.by.ip',
    key: 'ip',
  },
  {
    label: 'quantity',
    key: 'count',
  },
  {
    label: 'dashboard.details.table.header1',
    key: 'domains',
  },
];

export const TABLE_SPF_TOP_25 = [
  {
    key: 'ip',
    label: 'risk.matrix.table.ip',
  }, {
    key: 'sender',
    label: 'risk.matrix.table.senders',
  }, {
    key: 'total',
    label: 'risk.matrix.table.messages',
  }]
