import React, { useEffect, useState, useRef } from 'react';

const BASE_API = 'https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/v1/general-notes/client/';

export const FetchNote = ({ id, token, type = 0 }) => {
  const [respuesta, setRespuesta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // Variable de estado para almacenar el error

  useEffect(() => {
    const fetchData = async () => {
      const url = `${BASE_API}${id}/${type}`;
      try {
        setIsLoading(true);
        setError(null); // Reiniciar el error antes de realizar la petición

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            Accept: 'application/json',
          },
        });

        if (!response.ok) {
          let errorMessage = 'Error en la solicitud';
          if (response.status === 403) {
            const errorResponse = await response.json();
            errorMessage = errorResponse.message || 'Acceso denegado';
          }
          throw new Error(errorMessage);
        }

        const responseData = await response.json();
        setRespuesta(responseData);
      } catch (error) {
        setError(error.message); // Capturar el mensaje de error y almacenarlo en la variable de estado
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, token, type]);

  return { respuesta, isLoading, error };
};

export const FetchNoteCompany = ({ id, token, type = 0 }) => {
  const [respuesta, setRespuesta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null); // Utilizar una referencia mutable para almacenar el AbortController

  useEffect(() => {
    // Función para realizar la solicitud de red
    const fetchData = async () => {
      const abortController = new AbortController(); // Crear un nuevo AbortController
      abortControllerRef.current = abortController; // Guardar la referencia en la referencia mutable

      const url = `${BASE_API}${id}/${type}`;
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            Accept: 'application/json',
          },
          signal: abortController.signal, // Utilizar la señal de aborto del AbortController
        });

        if (!response.ok) {
          let errorMessage = 'Error en la solicitud';
          if (response.status === 403) {
            const errorResponse = await response.json();
            errorMessage = errorResponse.message || 'Acceso denegado';
          }
          throw new Error(errorMessage);
        }

        const responseData = await response.json();
        setRespuesta(responseData);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      // Cancelar la solicitud si la referencia mutable tiene un valor
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [id, token, type]);

  return { respuesta, isLoading, error };
};
