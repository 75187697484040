import {
  GET_REPORT_MONTH,
  GET_REPORT_MONTH_ERROR,
  GET_REPORT_MONTH_SUCCESS,
  GET_REPORT_TAB_MONTH,
} from 'rdx/newRedux/types';

const month = (state = {}, { type, payload }) => {
  const response = {
    [GET_REPORT_MONTH]: { loading: true },
    [GET_REPORT_MONTH_SUCCESS]: { loading: false, data: payload },
    [GET_REPORT_MONTH_ERROR]: { loading: false, error: payload },
    [GET_REPORT_TAB_MONTH]: { tab: payload },
  }
  return { ...state, ...response[type] } || state;
}
export default month;
