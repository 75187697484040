import {
  GET_COMPANY, GET_COMPANY_SUCCESS, GET_COMPANY_ERROR, SET_ACTUAL_COMPANY,
} from 'rdx/newRedux/types';


export const company = (state = {}, { type, payload }) => {
  const result = {
    [GET_COMPANY]: { loading: true },
    [GET_COMPANY_SUCCESS]: { loading: false, data: payload },
    [GET_COMPANY_ERROR]: { loading: false, error: payload },
    [SET_ACTUAL_COMPANY]: { actual: payload },
  }
  return { ...state, ...result[type] } || { ...state };
}
