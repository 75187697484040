import React, { useState } from "react";
import { NotesForm } from "../NotesForm";

export const NotesCreation = ({ noteData, actualId, setIsNoteSubmitted }) => {
  const token = noteData.jwtToken;
  const id = actualId.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = async (payload) => {
    try {
      setIsLoading(true);

      const response = await fetch(
        "https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/v1/general-notes",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ id, ...payload }),
        }
      );

      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }

      setIsSubmitted(true);
      setIsNoteSubmitted(true);
      // Restablecer los campos de entrada después de enviar la nota
      console.log("Nota enviada exitosamente", response);
    } catch (error) {
      console.error("Error al enviar la nota:", error);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div>
        <NotesForm onSubmit={handleSubmit} disabled={isLoading} error={error} isSubmitted={isSubmitted}/>
    </div>
  );
};
