import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { SuccessNote, ErrorNote } from '../SuccessNote'
import { FormattedMessage } from 'react-intl';

export const NotesForm = ({ onSubmit, disabled, isSubmitted, error }) => {
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [formError, setFormError] = useState("");

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validación de campos obligatorios
    if (title.trim() === "" || note.trim() === "") {
      setFormError(<FormattedMessage id="error.note.inputs"/>);
      return;
    }

    const payload = {
      title,
      note,
    };

    onSubmit(payload);
    setTitle("");
    setNote("");
    setFormError("");
  }
  const handleCloseAlert = () => {
    setFormError("");
  };
  return (
    <Box
      component="form"
      sx={{
        minWidth: "100%",
        margin: "0 auto",
        backgroundColor: "white",
        height: "445px",
        borderRadius: "4px",
        margin: "45px 0",
        padding: "20px 0px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        "& .MuiTextField-root": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <Typography
        variant="h2"
        gutterBottom
        align="center"
        sx={{
          fontFamily: "'Muli',sans-serif",
          fontSize: "22px",
          fontWeight: "bold",
          margin: "20px",
          color: "#3E97E8"
        }}
      >
        <FormattedMessage id="create.note"/>
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="outlined-multiline-flexible"
            label={<FormattedMessage id="create.note.title"/>}
            multiline
            maxRows={4}
            fullWidth
            sx={{ width: "97.4%" }}
            value={title}
            onChange={handleTitleChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-multiline-static"
            label={<FormattedMessage id="create.note.message"/>}
            multiline
            rows={8}
            sx={{ width: "97.4%" }}
            value={note}
            onChange={handleNoteChange}
            disabled={disabled}
            required
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={disabled}
        sx={{ margin: "20px auto", display: "block", width: "140px" }}
      >
        {disabled ? <CircularProgress disableShrink size={24} color="primary"/> : <FormattedMessage id="submit"/>}
      </Button>

      {formError && (
        <ErrorNote error={formError} handleCloseAlert={handleCloseAlert}/>
      )}
      {isSubmitted && <SuccessNote isSubmitted={isSubmitted}/>}
      {error && <ErrorNote error={error}/>}
    </Box>
  );
};
