import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'common/components/Layout';
import Grid from '@material-ui/core/Grid';
import { KEY_ACTUAL_COMPANY } from 'rdx/newRedux/selectores/keys';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { ReportsTable } from 'common/components/ReportsTable';
import Domains from 'views/riskmatrix/Domains';
import TitleSection from 'common/components/Message/TitleSection';
import { RangeSelector } from 'common/components/RangeSelector';
import Container from 'common/components/FlexContainer/Container';
import Skeleton from '@mui/material/Skeleton';
import { FormattedMessage } from 'react-intl';
import { asyncActions } from 'rdx/summary/actions';
import { currentDomainSelector, domainDetailsSelector } from 'rdx/summary/selectors';
import isEmpty from 'lodash/isEmpty';
import { FetchReport } from '../../../servicio/FetchReport';

export const ReportsReceived = () => {
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const domain = window.location.href.split('domain=').pop().trim();
  const actualId = GetSelector(KEY_ACTUAL_COMPANY)
  const [responseData, setResponseData] = useState(null);
  const [selectedRange, setSelectedRange] = useState(14);
  const [errorName, setErrorName] = useState(null);
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const domainP = useSelector(currentDomainSelector);
  const domainDetails = useSelector(domainDetailsSelector);
  useEffect(() => {
    setLoading(true);
    if (actualId && noteData) {
      const { email } = noteData.payload;
      const customerId = actualId.id;
      if (isEmpty(domainDetails)) dispatch(asyncActions.loadAction({ customerId, email }));
    }
  }, [dispatch, domainP, domainDetails, actualId, noteData]);

  useEffect(() => {
    const type = selectedRange;
    setLoading(true);
    setErrorName(null);
    setResponseData(null);
    if (noteData && actualId) {
      setLoading(true)
      FetchReport(noteData, domain, actualId, type)
        .then((response) => {
          setLoading(false);
          if (response.error) {
            setErrorName(response.error);
          } else {
            setResponseData(response.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
          setErrorName(error);
        });
    }
  }, [noteData, actualId, selectedRange, domain]);
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <TitleSection id="report.received" />
          <Grid item xs={12}>
            <RangeSelector onSelect={(range) => setSelectedRange(range)} />
          </Grid>
          <Grid item xs={12}>
            <Domains type="reports-received" />
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Container marginTop={64}>
            <Container marginTop={25}>
              <Container>
                {loading && !responseData && <Skeleton width="100%" height={200} animation="wave" />}
                {errorName && <h3 style={{ color: 'red', textAlign: 'center' }}><FormattedMessage id="fetch.error" /></h3>}
                {responseData && (
                  <ReportsTable data={responseData} />
                )}
              </Container>
            </Container>
          </Container>
        </Grid>
      </Grid>
    </Layout>
  );
};
