import React from 'react';
import Grid from '@material-ui/core/Grid';
import DmarcTable from 'common/components/Table/DmarcTable';
import Separator from 'common/components/Separator';

const Table = ({ dnsA = [], dnsAA = [] }) => {
  const setDetails = (actual) => actual.map((i) => [i])
  return (
    <Grid spacing={1} container>
      <Grid item md={12}>
        <Separator margin="5px" />
      </Grid>
      {dnsA.length > 0 && (
      <Grid item md={6} xs={12}>
        <DmarcTable
          maxHeight={458}
          classname="light"
          titles={[{
            label: 'dns.a',
            key: 'dns_a',
          }]}
          details={setDetails(dnsA)}
        />
      </Grid>
      )
      }
      { dnsAA.length > 0
      && (
      <Grid item md={6} xs={12}>
        <DmarcTable
          maxHeight={458}
          classname="light"
          titles={[{
            label: 'dns.aaa',
            key: 'dns_aaa',
          }]}
          details={setDetails(dnsAA)}
        />
      </Grid>
      )
      }
    </Grid>
  );
};

export default Table;
