import React from 'react';
import { format } from 'date-fns';
import { LowPriority, PriorityHigh } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const parseNotes = (data, isLocal = false) => {
  const act = data.map((it) => {
    let icon = (
      <Tooltip
        placement="left"
        title={<FormattedMessage id="normal" />}
      >
        <LowPriority
          color="primary"
        />
      </Tooltip>
    )
    if (it.type === 'UR') {
      icon = (
        <Tooltip
          placement="left"
          title={<FormattedMessage id="urgent" />}
        >
          <PriorityHigh
            color="error"
          />
        </Tooltip>
      )
    }
    return {
      type: icon,
      date: format(new Date(it.date), 'yyyy-MM-dd'),
      note: it.note,
    }
  })
  if (isLocal) {
    return act[0];
  }
  return act;
}
