import { combineReducers } from 'redux';
import { company } from 'rdx/newRedux/reducer/company';
import userInfo from 'rdx/newRedux/reducer/user';
import { utils } from 'rdx/newRedux/reducer/utils';
import { LookALik } from 'rdx/newRedux/reducer/lookALike';
import { Notes } from 'rdx/newRedux/reducer/notes';
import { USER_LOGOUT } from './user/actions';
import records from './records/reducers';

import dialogs from './dialogs/reducers';
import summary from './summary/reducers';
import user from './user/reducers';
import {
  forensic, histogram, month, report360, historicalDns,
} from './newRedux/reducer/reports';
import { tools } from './newRedux/reducer/tools';
import noteDataReducer from './newRedux/reducer/NoteReducer/NoteReducer';

export const appReducers = combineReducers({
  records,
  dialogs,
  summary,
  user,
  LookALik,
  userInfo,
  utils,
  forensic,
  histogram,
  month,
  Notes,
  report360,
  tools,
  company,
  historicalDns,
  noteData: noteDataReducer,
});

/*
 * When the action type is equal to USER_LOGOUT
 * reset the application state. In case another
 * user with new data logs in.
 */
export default (state, action) => {
  let nextState = state;
  if (action.type === USER_LOGOUT) {
    nextState = undefined;
  }

  return appReducers(nextState, action);
}
