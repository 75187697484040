import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import WhiteIcon from 'common/components/Utilities/WhiteIcon';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Font from 'common/components/Font';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

const LookALikeListItem = ({ history }) => {
  return (
    <ListItem button onClick={history.push.bind(this, '/look-like')}>
      <ListItemIcon>
        { WhiteIcon(TrackChangesIcon)}
      </ListItemIcon>
      <ListItemText
        primary={(
          <Font variant="h4" component="span" color="secondary">
            <FormattedMessage id="lookALike" />
          </Font>
        )}
      />
    </ListItem>
  );
};

export default withRouter(LookALikeListItem);
