export const KEY_DISPLAY_SELECTED = 'summary._root.entries[0][1]';
export const KEY_MESSAGE_ERROR_LOGIN = 'login.message';
export const KEY_LANGUAGE = 'user._root.entries[0][1]';

export const KEY_REPORT_LOADING = 'report360.reportLoading';
export const KEY_REPORT_DETAIL = 'report360.reportDetail';
export const KEY_REPORT_ERROR = 'report360.reportError';
export const KEY_DATE_REPORT_FILTER = 'report360.filters';

export const KEY_REPORT_LOADING_FORENSIC = 'forensic.reportLoading';
export const KEY_REPORT_DETAIL_FORENSIC = 'forensic.reportDetail';
export const KEY_DATE_REPORT_FILTER_FORENSIC = 'forensic.filters';

export const KEY_REPORT_LOADING_HISTOGRAM = 'histogram.reportLoading';
export const KEY_REPORT_DETAIL_HISTOGRAM = 'histogram.reportDetail';

export const KEY_LOADING_ITEM_MATRIX_SELECTED = 'dialogs._root.entries[1][1]';

export const KEY_GET_TOOLS = 'tools.data';
export const KEY_LOADING_GET_TOOLS = 'tools.loading';
export const KEY_GET_DOMAIN = 'summary._root.entries[1][1]';
export const KEY_GET_DOMAIN_LIST = 'summary._root.entries[2][1]';
export const KEY_REPORT_MONTH_LOADING = 'month.loading';
export const KEY_REPORT_MONTH_DETAIL = 'month.data';
export const KEY_REPORT_MONTH_TAB = 'month.tab';

export const KEY_COMPANY = 'company.data';
export const KEY_ACTUAL_COMPANY = 'company.actual';

export const KEY_USER_INFO = 'userInfo.data';

export const KEY_ACTUAL_URL = 'utils.url';
export const KEY_ERROR_LOAD_MATRIZ = 'utils.errorMatriz';
export const KEY_ERROR_LOAD_MATRIZ_LOADING = 'utils.loadMatriz';
export const KEY_IS_LOADING_DATA = 'utils.loading';

export const KEY_LOOK_A_LIKE = 'LookALik.data';
export const KEY_LOOK_A_LIKE_BY_IP = 'LookALik.dataIp';
export const KEY_LOOK_A_LIKE_LOADING = 'LookALik.loading';
export const KEY_LOOK_A_LIKE_SELECTED = 'LookALik.selected';
export const KEY_NOTES = 'Notes.data';
export const KEY_ADITIONAL_NOTE = 'Notes.dataAditional';
export const KEY_CREATE_NOTE_LOADING = 'Notes.loadingCreate';
export const KEY_CREATE_NOTE_DATA = 'Notes.dataCreate';
export const KEY_CREATE_NOTE_ERROR = 'Notes.errorCreate';
export const KEY_HISTORICAL_DNS = 'historicalDns.data';
export const KEY_HISTORICAL_DNS_LOADING = 'historicalDns.loading';
