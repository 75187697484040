import * as TYPE from 'rdx/newRedux/types';
import * as URL from './url';

const dataApi = {
  report: {
    url: URL.REPORT_URL,
    method: 'GET',
    success: TYPE.GET_REPORT_SUCCESS,
    error: TYPE.GET_REPORT_ERROR,
  },
  reportByDates: {
    url: URL.REPORT_URL_BY_DATE,
    method: 'GET',
    success: TYPE.GET_REPORT_SUCCESS,
    error: TYPE.GET_REPORT_ERROR,
  },
  reportForensic: {
    url: URL.REPORT_FORENSIC_URL,
    method: 'GET',
    success: TYPE.GET_REPORT_SUCCESS_FORENSIC,
    error: TYPE.GET_REPORT_ERROR_FORENSIC,
  },
  reportHistogram: {
    url: URL.REPORT_HISTOGRAM_URL,
    method: 'POST',
    success: TYPE.GET_HISTOGRAM_SUCCESS,
    error: TYPE.GET_HISTOGRAM_ERROR,
  },
  getToolsData: {
    url: URL.TOOLS_URL,
    method: 'GET',
    success: TYPE.GET_TOOLS_SUCCESS,
    error: TYPE.GET_TOOLS_ERROR,
  },
  reportMonth: {
    url: URL.REPORT_MONTH_URL,
    method: 'GET',
    success: TYPE.GET_REPORT_MONTH_SUCCESS,
    error: TYPE.GET_REPORT_MONTH_ERROR,
  },
  company: {
    url: URL.COMPANY_URL,
    method: 'GET',
    success: TYPE.GET_COMPANY_SUCCESS,
    error: TYPE.GET_COMPANY_ERROR,
  },
  lookALike: {
    url: URL.LOOK_A_LIKE_URL,
    method: 'GET',
    success: TYPE.LOOK_LIKE_SUCCESS,
    error: TYPE.LOOK_LIKE_ERROR,
  },
  lookALikeByIp: {
    url: URL.LOOK_A_LIKE_BY_IP_URL,
    method: 'POST',
    success: TYPE.LOOK_LIKE_SUCCESS_BY_IP,
    error: TYPE.LOOK_LIKE_ERROR_BY_IP,
  },
  getNotes: {
    url: URL.NOTES_URL,
    method: 'GET',
    success: TYPE.GET_NOTES_SUCCESS,
    error: TYPE.GET_NOTES_ERROR,
  },
  createNote: {
    url: URL.CREATE_NOTE_URL,
    method: 'POST',
    success: TYPE.CREATE_NOTES_SUCCESS,
    error: TYPE.CREATE_NOTES_ERROR,
  },
  getHistoricalDns: {
    url: URL.HISTORICAL_DNS_URL,
    method: 'GET',
    success: TYPE.HISTORICAL_DNS_SUCCESS,
    error: TYPE.HISTORICAL_DNS_ERROR,
  },
}

export default dataApi;
