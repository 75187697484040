import React from 'react';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import { CloudDownload } from '@material-ui/icons';

const Simple = ({
  name = 'report', data, onOpen = (event, done) => done(true),
}) => {
  return (
    <Tooltip title={<FormattedMessage id="download.csv" />} placement="top">
      <CSVLink asyncOnClick filename={`${name}.csv`} data={data} onClick={onOpen}>
        <CloudDownload color="primary" fontSize="large" />
      </CSVLink>
    </Tooltip>
  );
};

export default injectIntl(Simple);
