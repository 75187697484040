import React from 'react';
import { Chart } from 'react-google-charts';
import { theme } from 'src/theme';

const Bar = ({ data, titles }) => {
  const options = {
    ...titles,
    titleTextStyle: {
      color: theme.colors.blue1,
      fontSize: 25,
    },
    seriesType: 'bars',
    series: data && data[0].length > 2 ? { 1: { type: 'line' } } : {},
  };
  return (
    <Chart
      chartType="ComboChart"
      width="96%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
export default Bar;
