import React from 'react';
import DmarcTable from 'common/components/Table/DmarcTable';
import { TABLE_SPF_TOP_25 } from 'common/constants/tabsTitles';
import MessageBox from 'common/components/Message/MessageBox';
import { FormattedMessage, injectIntl } from 'react-intl';
import { theme } from 'src/theme';
import TableCont from 'common/components/Table/TableCont';
import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';

function TableDetail({ details = [], intl }) {
  const formatData = {
    format: {
      total: (d) => FormatNumberESService.formatNumber(intl, d),
    },
  }
  return (
    <React.Fragment>
      {
        details.length > 0 ? (
          <TableCont>
            <DmarcTable
              details={details || []}
              titles={TABLE_SPF_TOP_25}
              classname="dark"
              formatData={formatData}
            />
          </TableCont>
        ) : (
          <MessageBox
            message={<b><FormattedMessage id="not.have.data" /></b>}
            variant="primary"
            rest={{ color: theme.colors.grey5, bg: theme.colors.blue1 }}
          />
        )
      }
    </React.Fragment>
  );
}

export default injectIntl(TableDetail);
