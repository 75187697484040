import React, { useState } from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DoneIcon from '@mui/icons-material/Done';
import { UpdateNote } from 'servicio/FetchUpdateNote';
import { useSelector } from 'react-redux';
import { ErrorNote } from 'common/components/SuccessNote';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: theme.palette.primary.main, // Color de fondo normal
    color: '#fff', // Color de texto normal
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, // Color de fondo al hacer hover
    },
  },
}));

const style = {
  margin: 0,
  // right: 60,
  // bottom: 'auto',
  // position: 'absolute',
};

const actions = [
  { icon: <DoneIcon />, name: 'Done' },
  { icon: <EditNoteIcon />, name: 'Edit' },
  { icon: <AddIcon />, name: 'Add follow-up' },
];

export const NotesTooltipMenu = ({
  handleModal, handleFollowUpModal, setIsSubmitted, data,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(null);
  const noteData = useSelector((NotesState) => NotesState.noteData);
  const url = 'https://vbrqhkvfw3.execute-api.us-east-1.amazonaws.com/v1/notes/update-notes';
  const method = 'POST';
  const token = noteData.jwtToken;
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);


  const openConfirmation = () => {
    setOpenConfirmationModal(true);
  };
  const closeConfirmation = () => {
    setOpenConfirmationModal(false);
  };


  const actionFilters = data.note_subtype === 'S' ? actions : actions.filter((item) => item.name !== 'Add');

  const handleDoneConfirmation = () => {
    openConfirmation();
  };

  const handleDone = async () => {
    closeConfirmation()
    try {
      const requestData = {
        id: data.id,
        table: 'P',
        description: data.description,
        responsable: data.responsable,
        note_type: data.note_type,
        note_subtype: data.note_subtype,
        status: 'C',
        date_followup: data.date_followup,
      };
      await UpdateNote({
        requestData, token, method, url,
      });
      setIsSubmitted(true);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{
      width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'right',
    }}
    >
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        style={style}
        hidden={false}
        icon={<SpeedDialIcon />}
        direction="left"
        FabProps={{
          size: 'small',
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actionFilters.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement="top"
            onClick={() => {
              if (action.name === 'Edit') handleModal();
              if (action.name === 'Done') handleDoneConfirmation();
              if (action.name === 'Add follow-up') handleFollowUpModal();
            }}
          />
        ))}
      </SpeedDial>
      <Dialog open={openConfirmationModal} onClose={closeConfirmation}>
        <DialogTitle><FormattedMessage id="confirmation" /></DialogTitle>
        <DialogContent>
          <p><FormattedMessage id="CloseNoteMessage" /></p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmation} color="primary">
            <FormattedMessage id="Cancel" />
          </Button>
          <Button className={classes.customButton} onClick={handleDone} color="primary">
            <FormattedMessage id="Accept" />
          </Button>
        </DialogActions>
      </Dialog>

      {errorMessage && <ErrorNote error={errorMessage} /> }
    </div>
  );
}
