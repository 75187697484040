import { all } from 'redux-saga/effects';
import {
  getReports, getForensic, getHistogram, getMonth, getHistorcialDns,
} from 'rdx/newRedux/sagas/reports';
import { getTools } from 'rdx/newRedux/sagas/tools';
import { getCompany } from 'rdx/newRedux/sagas/company';
import { getLookALike, getLookALikeByIp } from 'rdx/newRedux/sagas/lookALike';
import { getNotes, createNote } from 'rdx/newRedux/sagas/notes';
import { recordSagas } from './records/sagas';
import { summarySagas } from './summary/sagas';

export default function* sagas() {
  yield all([
    ...recordSagas,
    ...summarySagas,
    createNote(),
    getReports(),
    getLookALike(),
    getLookALikeByIp(),
    getForensic(),
    getHistogram(),
    getHistorcialDns(),
    getMonth(),
    getTools(),
    getCompany(),
    getNotes(),
  ]);
}
