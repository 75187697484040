import React from 'react';
import { Grid } from '@material-ui/core';
import TabPanel from 'common/components/TabPanel/TabPanel';
import TableDetail from 'views/reports/month/type/typeN/TableDetail';
import { useDispatch } from 'react-redux';
import AsyncAction from 'rdx/newRedux/actions/AsyncAction';
import { GET_REPORT_TAB_MONTH } from 'rdx/newRedux/types';

const TypeN = ({ keyReport, data, intl }) => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = React.useState(0);
  const onChangeTab = (val) => {
    setTabIndex(val);
    dispatch(AsyncAction(GET_REPORT_TAB_MONTH, val));
  }
  React.useEffect(() => {
    setTabIndex(0);
  }, [data]);
  const labels = data.map((item, i) => ({ label: item.translate, id: i }))
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <TabPanel titles={labels} tabIndex={tabIndex} setTabIndex={onChangeTab} />
        {data[tabIndex]
        && (
        <TableDetail
          keyReport={keyReport}
          intl={intl}
          data={data}
          tabIndex={tabIndex}
          title={labels[tabIndex].label}
        />
        )}

      </Grid>
    </Grid>
  );
};

export default TypeN;
