import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom'
import Layout from 'common/components/Layout';
import Grid from '@material-ui/core/Grid';
import Domains from 'views/riskmatrix/Domains';
import { asyncActions, selectDisplay } from 'rdx/summary/actions';
import { currentDomainSelector, domainDetailsSelector } from 'rdx/summary/selectors';
import { connect, useDispatch } from 'react-redux';
import { withUserInfo } from 'common/components/Utilities/AuthProviders';
import Container from 'common/components/FlexContainer/Container';
import SkeletoLoading from 'common/components/Skeleton/SkeletoLoading';
import Details from 'views/reports/histogram/Details';
import { Scroll } from 'common/utils/services/scroll.service';
import GetSelector from 'rdx/newRedux/selectores/GetSelector';
import { KEY_REPORT_DETAIL_HISTOGRAM } from 'rdx/newRedux/selectores/keys';
import dataApi from 'rdx/newRedux/api/dataApi';
import Action from 'rdx/newRedux/actions/Action';
import { GET_HISTOGRAM } from 'rdx/newRedux/types';
import TitleSection from 'common/components/Message/TitleSection';

const View = ({
  customerId, loadPrecalculated, domainDetails, domain, email,

}) => {
  const reports = GetSelector(KEY_REPORT_DETAIL_HISTOGRAM);
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (customerId && domain) {
      const payload = {
        data: {
          id: customerId,
          domain,
        },
        ...dataApi.reportHistogram,
      }
      dispatch(Action(GET_HISTOGRAM, payload));
    }
  }, [customerId, domain]);
  React.useEffect(() => {
    if (customerId && isEmpty(domainDetails)) loadPrecalculated({ customerId, email });
    Scroll.scrollTop()
  }, [customerId]);
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <TitleSection id="menu.reports.histogram" />
          <Grid item xs={12}>
            <Domains type="histogram" />
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          {reports
            ? (
              <Container marginTop={65}>
                <Details data={reports} />
              </Container>
            )
            : <SkeletoLoading />
          }
        </Grid>
      </Grid>
    </Layout>
  );
}
const mapDispatchToProps = {
  loadPrecalculated: asyncActions.loadAction,
  setDisplay: selectDisplay,

}
const mapStateToProps = (state) => ({
  domain: currentDomainSelector(state),
  domainDetails: domainDetailsSelector(state),
})
export default connect(
  mapStateToProps, mapDispatchToProps
)(
  withRouter(withUserInfo(View))
);
