import { makeStyles } from '@material-ui/core';
import { theme } from 'src/theme';

const padding = {
  paddingLeft: 10,
  paddingTop: 10,
};
const useClass = makeStyles({
  titleTypeCard: {
    ...padding,
    paddingBottom: 10,
    backgroundColor: theme.colors.blue1,
    color: '#fff',
  },
  contentCardType1: {
    ...padding,
  },
  gridTotal: {
    justifyContent: 'center',
    display: 'flex',
  },
  title: {
    marginBottom: 15,
    color: theme.colors.blue1,
    fontWeight: 'bold',
    textAlign: 'center !important',
  },
  datePicker: {
    width: 100,
  },
  typeNVisible: {
    display: 'none',
  },
  buttonDownloadTypeN: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
});
export default useClass;
/*

const useClass = makeStyles({
  title: {
    fontSize: 20,
    color: theme.colors.blue4,
    marginBottom: 10,
  },
})
 */
