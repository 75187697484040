import {
  LOOK_LIKE,
  LOOK_LIKE_SUCCESS,
  LOOK_LIKE_ERROR,
  LOOK_LIKE_TYPE,
  LOOK_LIKE_BY_IP,
  LOOK_LIKE_SUCCESS_BY_IP, LOOK_LIKE_ERROR_BY_IP,
} from 'rdx/newRedux/types';


export const LookALik = (state = { selected: 0 }, { type, payload }) => {
  const result = {
    [LOOK_LIKE]: { loading: true },
    [LOOK_LIKE_SUCCESS]: { loading: false, data: payload },
    [LOOK_LIKE_ERROR]: { loading: false, error: payload },
    [LOOK_LIKE_BY_IP]: { loadingByIp: true },
    [LOOK_LIKE_SUCCESS_BY_IP]: { loadingByIp: false, dataIp: payload },
    [LOOK_LIKE_ERROR_BY_IP]: { loadingByIp: false, errorIp: payload },
    [LOOK_LIKE_TYPE]: { selected: payload },
  }
  return { ...state, ...result[type] } || { ...state };
}
