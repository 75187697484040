import React from 'react';
import {
  FormatAlignCenterOutlined,
  VerticalAlignBottom,
  VerticalAlignTop,
} from '@material-ui/icons';
import NavigationList from 'common/components/Navigation/NavigationList';

const data = [
  { url: '/spf-valid', label: 'spf.report.top25.valid', icon: VerticalAlignTop },
  { url: '/spf-invalid', label: 'spf.report.top25.invalid', icon: VerticalAlignBottom },

];
const icon = FormatAlignCenterOutlined;
const title = 'spf.report';
const ReportList = () => <NavigationList icon={icon} data={data} title={title} />

export default ReportList;
//         <NavigationListItem url="/report-monthly" label="reports.month" icon={EventNote} />
