import React from 'react';
import {
  uniqueId, keys, filter as fil, isEmpty,
} from 'lodash'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import Tooltip from '@material-ui/core/Tooltip';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Font from 'common/components/Font';
import { FormatNumberESService } from 'common/utils/services/formatNumberES.service';
import styled from 'styled-components';
import { theme } from 'src/theme';
import {
  TableVariants, FormattingOptions, Item, RowWrapper, EmptyMessage, ContentItem,
} from './index'

const Rounded = styled.div((props) => (`
  width: 20px;
  height: 20px;
  background: ${props.bg || 'inherit'};
  border-radius: 20px;
  cursor: pointer;
`));

const Disposition = ({ detail }) => {
  const [it, setIt] = React.useState([]);
  React.useEffect(() => {
    if (detail && detail.records && it.length === 0) {
      detail.records
        .forEach((k) => (!it.includes(k.PoliceEvaluated) && setIt([...it, k.PoliceEvaluated])))
    }
  })

  if (it.length > 0) {
    const colors = {
      none: theme.colors.green,
      reject: theme.colors.red2,
      quarantine: theme.colors.yellow,
    }

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        {it.map((l) => (
          <Tooltip
            key={uniqueId()}
            title={(
              <React.Fragment>
                <div>
                  <Font
                    variant="h1"
                    component="span"
                    style={{
                      fontSize: 16,
                      color: theme.colors.grey5,
                    }}
                  >
                    <b><FormattedMessage id={l} /></b>
                  </Font>
                </div>
              </React.Fragment>
            )
            }
            placement="top"
          ><Rounded bg={colors[l.toLowerCase()]} key={l} />
          </Tooltip>
        ))}
      </div>
    )
  }
  return null
}
export const DetailsSubTable = ({
  variant,
  summary,
  filter,
  intl,
}) => {
  const [details, setDetails] = React.useState();
  const [act, setAct] = React.useState();
  React.useEffect(() => {
    if (!act) {
      if (variant === TableVariants.SENDER) {
        setAct(summary.recordsSummaryByIp());
      } else {
        setAct(summary.recordsByCombinations());
      }
    }
  });
  React.useEffect(() => {
    if (!details && filter && act) setDetails(fil(act, (k) => keys(k)[0].includes(filter)));
    if (!details && act && !filter) setDetails(act);
  }, [act]);
  React.useEffect(() => {
    if (filter && act) {
      setDetails(fil(act, (k) => keys(k)[0].includes(filter)));
    } else if (!filter) {
      setDetails(act);
    }
  }, [filter]);
  if (!details) return null


  const Row = ({
    index,
    style,
  }) => {
    const odd = index % 2 !== 0
    const ipDetail = details[index]
    const item = Object.keys(ipDetail)[0];
    return (
      <RowWrapper odd={odd} style={style}>
        {
          <Item align="center">
            <ContentItem>
              <b>{item}</b>
              {variant === TableVariants.SENDER
                && item && ipDetail[item][0].records
                && ipDetail[item][0].records.length > 0
                && ipDetail[item][0].records[0].Blacklist && (
                  <Font variant="body2" color="error">
                    <FormattedMessage id="blacklisted" />
                  </Font>
              )
              }
            </ContentItem>
          </Item>
        }
        {
          Object.values(ipDetail)[0].map((detail) => (
            <React.Fragment key={uniqueId(`${Object.keys(ipDetail)[0]}-ip-details-`)}>
              <Item align="center">
                <Font variant="h5" component="span" className="blacklist">
                  <FormattedNumber
                    value={detail.dmarcAlignmentPassPerc}
                    {...FormattingOptions}
                  />
                </Font>
              </Item>

              <Item align="center">
                <Font variant="h5" component="span">
                  <FormattedNumber
                    value={detail.spfPassPerc}
                    {...FormattingOptions}
                  />
                </Font>
              </Item>
              <Item align="center">
                <Font variant="h5" component="span">
                  <FormattedNumber
                    value={detail.spfAlignmentPassPerc}
                    {...FormattingOptions}
                  />
                </Font>
              </Item>
              <Item align="center">
                <Font variant="h5" component="span">
                  <FormattedNumber
                    value={detail.dkimPassPerc}
                    {...FormattingOptions}
                  />
                </Font>
              </Item>
              <Item align="center">
                <Font variant="h5" component="span">
                  <FormattedNumber
                    value={detail.dkimAlignmentPassPerc}
                    {...FormattingOptions}
                  />
                </Font>
              </Item>
              {
                variant === TableVariants.SENDER ? (
                  <Item>{detail.ips}</Item>
                ) : null
              }
              <Item
                align="center"
              >{FormatNumberESService.formatNumber(intl, detail.recordsMessages)}
              </Item>
              {
                variant === TableVariants.IP && (
                  <React.Fragment>

                    <Item>
                      <Font variant="h5" component="span">
                        {detail.records[0].RecordIdentifiersEnvelopeFrom}
                      </Font>
                    </Item>
                    <Item>
                      <Font variant="h5" component="span">
                        {detail.records[0].RecordIdentifiersHeadersFrom}
                      </Font>
                    </Item>
                    <Item>
                      <Font variant="h5" component="span">
                        {detail.records[0].RecordAuthResultsDkmiDomain}
                      </Font>
                    </Item>
                    <Item align="center">
                      <Disposition detail={detail} />
                    </Item>
                  </React.Fragment>
                )
              }
            </React.Fragment>
          ))
        }
      </RowWrapper>
    )
  }

  if (isEmpty(details)) return <EmptyMessage><FormattedMessage id="not.have.data" /></EmptyMessage>

  return (
    <React.Fragment>
      <AutoSizer>
        {({
          height,
          width,
        }) => (
          <List
            null={false}
            height={height}
            itemCount={details.length}
            itemSize={70}
            width={width}
          >
            {Row}

          </List>
        )}
      </AutoSizer>
    </React.Fragment>
  )
}
