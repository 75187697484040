import { makeStyles } from '@material-ui/core';


const useClass = makeStyles((theme) => ({
  contentAccordion: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  titleDomain: {
    display: 'block',
    paddingLeft: 13,
    marginBottom: 10,
    color: theme.palette.grey['600'],
  },
  titleOriginalDomain: {
    display: 'block',
    marginBottom: 10,
    color: theme.palette.grey['600'],
  },
  titleAccordion: {
    height: 40,
    minHeight: 40,
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
}))

export default useClass
